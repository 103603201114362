import { Injectable } from '@angular/core';
import { GrpcLibService, GrpcLibServiceOption } from '../grpc-lib.service';
import { UserLibService } from '../../user/user-lib.service';
import { StorageLibService } from '../../storage/storage-lib.service';
import { EventConversionService } from '../../conversion/event/event-conversion.service';
import { Observable } from 'rxjs';
import * as grpcWeb from 'grpc-web';
import { EventMobile, ICS } from '../../../libs/proto/mobile_pb';
import { Empty, RequestID } from '../../../libs/proto/commUnity_pb';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GrpcEventLibService {

  constructor(
    private grpcLib: GrpcLibService,
    private userLib: UserLibService,
    private storLib: StorageLibService,
    private convLib: EventConversionService,
  ) { }



  getEvent(option?: GrpcLibServiceOption): Promise<EventMobile[]>{
    return new Observable<EventMobile[]>(obs => {
      const opt = this.grpcLib.getOption(option);
      opt.call.subscribe = obs;

      if (!this.grpcLib.Data.online || (opt.Offline || false)) {
        this._getEventOffline(opt);
      } else {
        this._getEventOnline(opt);
      }
    }).toPromise();
  }

  private _getEventOffline(option: GrpcLibServiceOption){
    this.convLib.FromStorages(
      this.storLib.get('event'), (ns, e) => {
        if (e == null) {
          if ((option.call.req || '') !== ''){
            if (ns) {
              option.call.subscribe.next( ns.filter( n => {
                if ((option.call.req as string).length !== 8) {
                  return n.getEvent().getId() === option.call.req;
                }
                return n.getEvent().getDate() === +option.call.req;
              }));
              option.call.subscribe.complete();
              return;
            }
          }
        }

        option.call.subscribe.next(ns || []);
        option.call.subscribe.complete();
    });
  }

  private _getEventOnline(option: GrpcLibServiceOption){
    this.grpcLib.MobileClient.getEvents(new Empty(), {
        token: this.userLib.Data.token?.getToken()
      })
      .on('error', (e: grpcWeb.RpcError) => {
        this._getEventOnlineError(e, option);
      })
      .on('status', (s: grpcWeb.Status) => {
        this._getEventOnlineStatus(s, option);
      })
      .on('data', (r: EventMobile) => {
        this._getEventOnlineData(r, option);
      })
      .on('end', () => {
        this._getEventOnlineEnd(option);
      });
  }

  private _getEventOnlineData(ret: EventMobile, option: GrpcLibServiceOption) {
    option.call.data.push(ret);
  }

  private _getEventOnlineError(e: grpcWeb.RpcError, option: GrpcLibServiceOption) {
    const thise = this;

    this.grpcLib.handleError(e, () => {
      thise._getEventOnline(option);
    }, option);
  }

  private _getEventOnlineEnd(option: GrpcLibServiceOption) {
    option.call.subscribe.complete();
  }

  private _getEventOnlineStatus(s: grpcWeb.Status, option: GrpcLibServiceOption) {
    if (s.code === 0) {
      this.storLib.cache.event = true;
      if (option.KeepInCache || false) {
        this.storLib.set(
          'event', this.convLib.ToStorages(option.call.data)
        );
      }

      option.call.subscribe.next(option.call.data);
      if (option?.callback) { option?.callback(option.call.data); }
    } else {
      this.grpcLib.treatStatus(s, () => {
        this._getEventOnline(option);
      }, option);
    }
  }
  eventAttend(id: string){
    return new Observable<ICS>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._eventAttendOnline(opt);

    }).toPromise();
  }

  private _eventAttendOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.eventAttend(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, ics) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._eventAttendOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(ics);
        option.call.subscribe.complete();
      }
    });
  }

  eventNotAttend(id: string){
    return new Observable<Empty>(obs => {
      const opt = this.grpcLib.getOption({
        call: {
          req: id
        }
      });
      opt.call.subscribe = obs;

      this._eventNotAttendOnline(opt);

    }).toPromise();
  }
  private _eventNotAttendOnline(option: GrpcLibServiceOption){
    const req = new RequestID();
    req.setId(option.call.req);

    this.grpcLib.MobileClient.eventNotAttend(req, {
      token: this.userLib.Data.token?.getToken()
    }, (e, _) => {

      if (e != null) {

        this.grpcLib.handleError(e, () => {
          this._eventNotAttendOnline(option);
        }, option);

      } else {
        option.call.subscribe.next(true);
        option.call.subscribe.complete();
      }
    });
  }
}
