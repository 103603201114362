// source: hotix.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commUnity_pb = require('./commUnity_pb.js');
goog.object.extend(proto, commUnity_pb);
goog.exportSymbol('proto.commUnity.AdminHotixReservation', null, global);
goog.exportSymbol('proto.commUnity.AdminHotixSatisfactionSurvey', null, global);
goog.exportSymbol('proto.commUnity.HotixBillLineResp', null, global);
goog.exportSymbol('proto.commUnity.HotixGuest', null, global);
goog.exportSymbol('proto.commUnity.HotixGuestResp', null, global);
goog.exportSymbol('proto.commUnity.HotixIdentityDocumentType', null, global);
goog.exportSymbol('proto.commUnity.HotixLevelSatisfaction', null, global);
goog.exportSymbol('proto.commUnity.HotixPaxDetailResp', null, global);
goog.exportSymbol('proto.commUnity.HotixPreCheckin', null, global);
goog.exportSymbol('proto.commUnity.HotixPreCheckinStatus', null, global);
goog.exportSymbol('proto.commUnity.HotixReservation', null, global);
goog.exportSymbol('proto.commUnity.HotixReservationDetail', null, global);
goog.exportSymbol('proto.commUnity.HotixReservationDetailResp', null, global);
goog.exportSymbol('proto.commUnity.HotixReservationResp', null, global);
goog.exportSymbol('proto.commUnity.HotixReservationStatus', null, global);
goog.exportSymbol('proto.commUnity.HotixRoomBillDetailResp', null, global);
goog.exportSymbol('proto.commUnity.HotixSatisfactionSurvey', null, global);
goog.exportSymbol('proto.commUnity.HotixSettings', null, global);
goog.exportSymbol('proto.commUnity.HotixStayReason', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixSettings.displayName = 'proto.commUnity.HotixSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.AdminHotixReservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.AdminHotixReservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.AdminHotixReservation.displayName = 'proto.commUnity.AdminHotixReservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixReservation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixReservation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixReservation.displayName = 'proto.commUnity.HotixReservation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixGuestResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixGuestResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixGuestResp.displayName = 'proto.commUnity.HotixGuestResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixReservationResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.HotixReservationResp.repeatedFields_, null);
};
goog.inherits(proto.commUnity.HotixReservationResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixReservationResp.displayName = 'proto.commUnity.HotixReservationResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixReservationDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixReservationDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixReservationDetail.displayName = 'proto.commUnity.HotixReservationDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixPaxDetailResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixPaxDetailResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixPaxDetailResp.displayName = 'proto.commUnity.HotixPaxDetailResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixReservationDetailResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.HotixReservationDetailResp.repeatedFields_, null);
};
goog.inherits(proto.commUnity.HotixReservationDetailResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixReservationDetailResp.displayName = 'proto.commUnity.HotixReservationDetailResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixBillLineResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixBillLineResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixBillLineResp.displayName = 'proto.commUnity.HotixBillLineResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixRoomBillDetailResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.HotixRoomBillDetailResp.repeatedFields_, null);
};
goog.inherits(proto.commUnity.HotixRoomBillDetailResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixRoomBillDetailResp.displayName = 'proto.commUnity.HotixRoomBillDetailResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixGuest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixGuest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixGuest.displayName = 'proto.commUnity.HotixGuest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixPreCheckin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.HotixPreCheckin.repeatedFields_, null);
};
goog.inherits(proto.commUnity.HotixPreCheckin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixPreCheckin.displayName = 'proto.commUnity.HotixPreCheckin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.AdminHotixSatisfactionSurvey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.AdminHotixSatisfactionSurvey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.AdminHotixSatisfactionSurvey.displayName = 'proto.commUnity.AdminHotixSatisfactionSurvey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.HotixSatisfactionSurvey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.HotixSatisfactionSurvey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.HotixSatisfactionSurvey.displayName = 'proto.commUnity.HotixSatisfactionSurvey';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
url: jspb.Message.getFieldWithDefault(msg, 10, ""),
pms: jspb.Message.getFieldWithDefault(msg, 20, ""),
username: jspb.Message.getFieldWithDefault(msg, 30, ""),
password: jspb.Message.getFieldWithDefault(msg, 40, ""),
hotelcode: jspb.Message.getFieldWithDefault(msg, 45, ""),
defaultmembertype: jspb.Message.getFieldWithDefault(msg, 50, ""),
precheckinmessageMap: (f = msg.getPrecheckinmessageMap()) ? f.toObject(includeInstance, undefined) : [],
refresheachxhours: jspb.Message.getFieldWithDefault(msg, 100, 0),
requestpickupatarrival: jspb.Message.getBooleanFieldWithDefault(msg, 110, false),
needselfie: jspb.Message.getBooleanFieldWithDefault(msg, 112, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixSettings}
 */
proto.commUnity.HotixSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixSettings;
  return proto.commUnity.HotixSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixSettings}
 */
proto.commUnity.HotixSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPms(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelcode(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultmembertype(value);
      break;
    case 60:
      var value = msg.getPrecheckinmessageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 100:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRefresheachxhours(value);
      break;
    case 110:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequestpickupatarrival(value);
      break;
    case 112:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedselfie(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPms();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getHotelcode();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getDefaultmembertype();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getPrecheckinmessageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(60, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getRefresheachxhours();
  if (f !== 0) {
    writer.writeUint32(
      100,
      f
    );
  }
  f = message.getRequestpickupatarrival();
  if (f) {
    writer.writeBool(
      110,
      f
    );
  }
  f = message.getNeedselfie();
  if (f) {
    writer.writeBool(
      112,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string URL = 10;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string PMS = 20;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getPms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setPms = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string UserName = 30;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string Password = 40;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string HotelCode = 45;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getHotelcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setHotelcode = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string DefaultMemberType = 50;
 * @return {string}
 */
proto.commUnity.HotixSettings.prototype.getDefaultmembertype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setDefaultmembertype = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * map<int32, string> PreCheckinMessage = 60;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.commUnity.HotixSettings.prototype.getPrecheckinmessageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 60, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.clearPrecheckinmessageMap = function() {
  this.getPrecheckinmessageMap().clear();
  return this;
};


/**
 * optional uint32 RefreshEachXHours = 100;
 * @return {number}
 */
proto.commUnity.HotixSettings.prototype.getRefresheachxhours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setRefresheachxhours = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional bool RequestPickupAtArrival = 110;
 * @return {boolean}
 */
proto.commUnity.HotixSettings.prototype.getRequestpickupatarrival = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 110, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setRequestpickupatarrival = function(value) {
  return jspb.Message.setProto3BooleanField(this, 110, value);
};


/**
 * optional bool NeedSelfie = 112;
 * @return {boolean}
 */
proto.commUnity.HotixSettings.prototype.getNeedselfie = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 112, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixSettings} returns this
 */
proto.commUnity.HotixSettings.prototype.setNeedselfie = function(value) {
  return jspb.Message.setProto3BooleanField(this, 112, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.AdminHotixReservation.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.AdminHotixReservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.AdminHotixReservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AdminHotixReservation.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
memberid: jspb.Message.getFieldWithDefault(msg, 10, ""),
member: (f = msg.getMember()) && commUnity_pb.Member.toObject(includeInstance, f),
status: jspb.Message.getFieldWithDefault(msg, 20, 0),
satisfactionsurveydone: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
hotixreservation: (f = msg.getHotixreservation()) && proto.commUnity.HotixReservationResp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.AdminHotixReservation}
 */
proto.commUnity.AdminHotixReservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.AdminHotixReservation;
  return proto.commUnity.AdminHotixReservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.AdminHotixReservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.AdminHotixReservation}
 */
proto.commUnity.AdminHotixReservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 15:
      var value = new commUnity_pb.Member;
      reader.readMessage(value,commUnity_pb.Member.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.HotixReservationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSatisfactionsurveydone(value);
      break;
    case 25:
      var value = new proto.commUnity.HotixReservationResp;
      reader.readMessage(value,proto.commUnity.HotixReservationResp.deserializeBinaryFromReader);
      msg.setHotixreservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.AdminHotixReservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.AdminHotixReservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.AdminHotixReservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AdminHotixReservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      commUnity_pb.Member.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getSatisfactionsurveydone();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getHotixreservation();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.commUnity.HotixReservationResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.AdminHotixReservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AdminHotixReservation} returns this
 */
proto.commUnity.AdminHotixReservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MemberID = 10;
 * @return {string}
 */
proto.commUnity.AdminHotixReservation.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AdminHotixReservation} returns this
 */
proto.commUnity.AdminHotixReservation.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Member Member = 15;
 * @return {?proto.commUnity.Member}
 */
proto.commUnity.AdminHotixReservation.prototype.getMember = function() {
  return /** @type{?proto.commUnity.Member} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Member, 15));
};


/**
 * @param {?proto.commUnity.Member|undefined} value
 * @return {!proto.commUnity.AdminHotixReservation} returns this
*/
proto.commUnity.AdminHotixReservation.prototype.setMember = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.AdminHotixReservation} returns this
 */
proto.commUnity.AdminHotixReservation.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.AdminHotixReservation.prototype.hasMember = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional HotixReservationStatus status = 20;
 * @return {!proto.commUnity.HotixReservationStatus}
 */
proto.commUnity.AdminHotixReservation.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.HotixReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.HotixReservationStatus} value
 * @return {!proto.commUnity.AdminHotixReservation} returns this
 */
proto.commUnity.AdminHotixReservation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional bool SatisfactionSurveyDone = 22;
 * @return {boolean}
 */
proto.commUnity.AdminHotixReservation.prototype.getSatisfactionsurveydone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.AdminHotixReservation} returns this
 */
proto.commUnity.AdminHotixReservation.prototype.setSatisfactionsurveydone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional HotixReservationResp HotixReservation = 25;
 * @return {?proto.commUnity.HotixReservationResp}
 */
proto.commUnity.AdminHotixReservation.prototype.getHotixreservation = function() {
  return /** @type{?proto.commUnity.HotixReservationResp} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.HotixReservationResp, 25));
};


/**
 * @param {?proto.commUnity.HotixReservationResp|undefined} value
 * @return {!proto.commUnity.AdminHotixReservation} returns this
*/
proto.commUnity.AdminHotixReservation.prototype.setHotixreservation = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.AdminHotixReservation} returns this
 */
proto.commUnity.AdminHotixReservation.prototype.clearHotixreservation = function() {
  return this.setHotixreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.AdminHotixReservation.prototype.hasHotixreservation = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixReservation.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixReservation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixReservation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservation.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
memberid: jspb.Message.getFieldWithDefault(msg, 10, ""),
status: jspb.Message.getFieldWithDefault(msg, 15, 0),
satisfactionsurveydone: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
showbill: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
hotixreservation: (f = msg.getHotixreservation()) && proto.commUnity.HotixReservationResp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixReservation}
 */
proto.commUnity.HotixReservation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixReservation;
  return proto.commUnity.HotixReservation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixReservation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixReservation}
 */
proto.commUnity.HotixReservation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 15:
      var value = /** @type {!proto.commUnity.HotixReservationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSatisfactionsurveydone(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowbill(value);
      break;
    case 20:
      var value = new proto.commUnity.HotixReservationResp;
      reader.readMessage(value,proto.commUnity.HotixReservationResp.deserializeBinaryFromReader);
      msg.setHotixreservation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixReservation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixReservation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixReservation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getSatisfactionsurveydone();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getShowbill();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getHotixreservation();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.commUnity.HotixReservationResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.HotixReservation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservation} returns this
 */
proto.commUnity.HotixReservation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MemberID = 10;
 * @return {string}
 */
proto.commUnity.HotixReservation.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservation} returns this
 */
proto.commUnity.HotixReservation.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional HotixReservationStatus status = 15;
 * @return {!proto.commUnity.HotixReservationStatus}
 */
proto.commUnity.HotixReservation.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.HotixReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.commUnity.HotixReservationStatus} value
 * @return {!proto.commUnity.HotixReservation} returns this
 */
proto.commUnity.HotixReservation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool SatisfactionSurveyDone = 17;
 * @return {boolean}
 */
proto.commUnity.HotixReservation.prototype.getSatisfactionsurveydone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixReservation} returns this
 */
proto.commUnity.HotixReservation.prototype.setSatisfactionsurveydone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool ShowBill = 18;
 * @return {boolean}
 */
proto.commUnity.HotixReservation.prototype.getShowbill = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixReservation} returns this
 */
proto.commUnity.HotixReservation.prototype.setShowbill = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional HotixReservationResp HotixReservation = 20;
 * @return {?proto.commUnity.HotixReservationResp}
 */
proto.commUnity.HotixReservation.prototype.getHotixreservation = function() {
  return /** @type{?proto.commUnity.HotixReservationResp} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.HotixReservationResp, 20));
};


/**
 * @param {?proto.commUnity.HotixReservationResp|undefined} value
 * @return {!proto.commUnity.HotixReservation} returns this
*/
proto.commUnity.HotixReservation.prototype.setHotixreservation = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.HotixReservation} returns this
 */
proto.commUnity.HotixReservation.prototype.clearHotixreservation = function() {
  return this.setHotixreservation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.HotixReservation.prototype.hasHotixreservation = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixGuestResp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixGuestResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixGuestResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixGuestResp.toObject = function(includeInstance, msg) {
  var f, obj = {
guestid: jspb.Message.getFieldWithDefault(msg, 1, 0),
master: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
qualityid: jspb.Message.getFieldWithDefault(msg, 10, 0),
quality: jspb.Message.getFieldWithDefault(msg, 15, ""),
firstname: jspb.Message.getFieldWithDefault(msg, 20, ""),
lastname: jspb.Message.getFieldWithDefault(msg, 25, ""),
nationality: jspb.Message.getFieldWithDefault(msg, 30, ""),
birthdate: jspb.Message.getFieldWithDefault(msg, 35, 0),
mobile: jspb.Message.getFieldWithDefault(msg, 40, ""),
email: jspb.Message.getFieldWithDefault(msg, 45, ""),
idnumber: jspb.Message.getFieldWithDefault(msg, 50, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixGuestResp}
 */
proto.commUnity.HotixGuestResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixGuestResp;
  return proto.commUnity.HotixGuestResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixGuestResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixGuestResp}
 */
proto.commUnity.HotixGuestResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGuestid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMaster(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQualityid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuality(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBirthdate(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixGuestResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixGuestResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixGuestResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixGuestResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuestid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMaster();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getQualityid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getQuality();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getNationality();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getBirthdate();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getIdnumber();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
};


/**
 * optional int64 GuestID = 1;
 * @return {number}
 */
proto.commUnity.HotixGuestResp.prototype.getGuestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setGuestid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool Master = 5;
 * @return {boolean}
 */
proto.commUnity.HotixGuestResp.prototype.getMaster = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setMaster = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 QualityID = 10;
 * @return {number}
 */
proto.commUnity.HotixGuestResp.prototype.getQualityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setQualityid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string Quality = 15;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getQuality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setQuality = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string FirstName = 20;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string LastName = 25;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string Nationality = 30;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setNationality = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional uint32 BirthDate = 35;
 * @return {number}
 */
proto.commUnity.HotixGuestResp.prototype.getBirthdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setBirthdate = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional string Mobile = 40;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string Email = 45;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string IDNumber = 50;
 * @return {string}
 */
proto.commUnity.HotixGuestResp.prototype.getIdnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuestResp} returns this
 */
proto.commUnity.HotixGuestResp.prototype.setIdnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.HotixReservationResp.repeatedFields_ = [80];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixReservationResp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixReservationResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixReservationResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservationResp.toObject = function(includeInstance, msg) {
  var f, obj = {
hotelcode: jspb.Message.getFieldWithDefault(msg, 1, ""),
resaid: jspb.Message.getFieldWithDefault(msg, 5, ""),
statusid: jspb.Message.getFieldWithDefault(msg, 10, 0),
status: jspb.Message.getFieldWithDefault(msg, 15, ""),
creationdate: jspb.Message.getFieldWithDefault(msg, 20, 0),
arrivaldate: jspb.Message.getFieldWithDefault(msg, 25, 0),
arrivaltime: jspb.Message.getFieldWithDefault(msg, 27, 0),
departuredate: jspb.Message.getFieldWithDefault(msg, 30, 0),
departuretime: jspb.Message.getFieldWithDefault(msg, 32, 0),
rateid: jspb.Message.getFieldWithDefault(msg, 35, 0),
havedeposit: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
depositreceived: jspb.Message.getFieldWithDefault(msg, 37, ""),
deposit: jspb.Message.getFieldWithDefault(msg, 38, ""),
rate: jspb.Message.getFieldWithDefault(msg, 40, ""),
roomtypeid: jspb.Message.getFieldWithDefault(msg, 45, 0),
roomtype: jspb.Message.getFieldWithDefault(msg, 50, ""),
companyid: jspb.Message.getFieldWithDefault(msg, 55, 0),
company: jspb.Message.getFieldWithDefault(msg, 60, ""),
subsegmentid: jspb.Message.getFieldWithDefault(msg, 65, 0),
subsegment: jspb.Message.getFieldWithDefault(msg, 70, ""),
comment: jspb.Message.getFieldWithDefault(msg, 75, ""),
guestsList: jspb.Message.toObjectList(msg.getGuestsList(),
    proto.commUnity.HotixGuestResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixReservationResp}
 */
proto.commUnity.HotixReservationResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixReservationResp;
  return proto.commUnity.HotixReservationResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixReservationResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixReservationResp}
 */
proto.commUnity.HotixReservationResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHotelcode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setResaid(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreationdate(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setArrivaldate(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setArrivaltime(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeparturedate(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeparturetime(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRateid(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHavedeposit(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositreceived(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setRate(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomtypeid(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompanyid(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSubsegmentid(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubsegment(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 80:
      var value = new proto.commUnity.HotixGuestResp;
      reader.readMessage(value,proto.commUnity.HotixGuestResp.deserializeBinaryFromReader);
      msg.addGuests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixReservationResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixReservationResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixReservationResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservationResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHotelcode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResaid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatusid();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCreationdate();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getArrivaldate();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getArrivaltime();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = message.getDeparturedate();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
  f = message.getDeparturetime();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getRateid();
  if (f !== 0) {
    writer.writeInt64(
      35,
      f
    );
  }
  f = message.getHavedeposit();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getDepositreceived();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getRate();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getRoomtypeid();
  if (f !== 0) {
    writer.writeInt64(
      45,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getCompanyid();
  if (f !== 0) {
    writer.writeInt64(
      55,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getSubsegmentid();
  if (f !== 0) {
    writer.writeInt64(
      65,
      f
    );
  }
  f = message.getSubsegment();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getGuestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      80,
      f,
      proto.commUnity.HotixGuestResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string HotelCode = 1;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getHotelcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setHotelcode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ResaID = 5;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getResaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setResaid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 StatusID = 10;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getStatusid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setStatusid = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string Status = 15;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 CreationDate = 20;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getCreationdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setCreationdate = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint32 ArrivalDate = 25;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getArrivaldate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setArrivaldate = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional uint32 ArrivalTime = 27;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getArrivaltime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setArrivaltime = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional uint32 DepartureDate = 30;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getDeparturedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setDeparturedate = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional uint32 DepartureTime = 32;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getDeparturetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setDeparturetime = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional int64 RateID = 35;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getRateid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setRateid = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional bool HaveDeposit = 36;
 * @return {boolean}
 */
proto.commUnity.HotixReservationResp.prototype.getHavedeposit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setHavedeposit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional string DepositReceived = 37;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getDepositreceived = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setDepositreceived = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string Deposit = 38;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string Rate = 40;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setRate = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional int64 RoomTypeID = 45;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getRoomtypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setRoomtypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional string RoomType = 50;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional int64 CompanyID = 55;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getCompanyid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 55, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setCompanyid = function(value) {
  return jspb.Message.setProto3IntField(this, 55, value);
};


/**
 * optional string Company = 60;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional int64 SubSegmentID = 65;
 * @return {number}
 */
proto.commUnity.HotixReservationResp.prototype.getSubsegmentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setSubsegmentid = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


/**
 * optional string SubSegment = 70;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getSubsegment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setSubsegment = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string Comment = 75;
 * @return {string}
 */
proto.commUnity.HotixReservationResp.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * repeated HotixGuestResp Guests = 80;
 * @return {!Array<!proto.commUnity.HotixGuestResp>}
 */
proto.commUnity.HotixReservationResp.prototype.getGuestsList = function() {
  return /** @type{!Array<!proto.commUnity.HotixGuestResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.HotixGuestResp, 80));
};


/**
 * @param {!Array<!proto.commUnity.HotixGuestResp>} value
 * @return {!proto.commUnity.HotixReservationResp} returns this
*/
proto.commUnity.HotixReservationResp.prototype.setGuestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 80, value);
};


/**
 * @param {!proto.commUnity.HotixGuestResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.HotixGuestResp}
 */
proto.commUnity.HotixReservationResp.prototype.addGuests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 80, opt_value, proto.commUnity.HotixGuestResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.HotixReservationResp} returns this
 */
proto.commUnity.HotixReservationResp.prototype.clearGuestsList = function() {
  return this.setGuestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixReservationDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixReservationDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixReservationDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservationDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
memberid: jspb.Message.getFieldWithDefault(msg, 10, ""),
reservationid: jspb.Message.getFieldWithDefault(msg, 15, ""),
status: jspb.Message.getFieldWithDefault(msg, 20, 0),
hotixreservationdetail: (f = msg.getHotixreservationdetail()) && proto.commUnity.HotixReservationDetailResp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixReservationDetail}
 */
proto.commUnity.HotixReservationDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixReservationDetail;
  return proto.commUnity.HotixReservationDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixReservationDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixReservationDetail}
 */
proto.commUnity.HotixReservationDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationid(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.HotixReservationStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 25:
      var value = new proto.commUnity.HotixReservationDetailResp;
      reader.readMessage(value,proto.commUnity.HotixReservationDetailResp.deserializeBinaryFromReader);
      msg.setHotixreservationdetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixReservationDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixReservationDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixReservationDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservationDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReservationid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getHotixreservationdetail();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.commUnity.HotixReservationDetailResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.HotixReservationDetail.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetail} returns this
 */
proto.commUnity.HotixReservationDetail.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MemberID = 10;
 * @return {string}
 */
proto.commUnity.HotixReservationDetail.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetail} returns this
 */
proto.commUnity.HotixReservationDetail.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ReservationID = 15;
 * @return {string}
 */
proto.commUnity.HotixReservationDetail.prototype.getReservationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetail} returns this
 */
proto.commUnity.HotixReservationDetail.prototype.setReservationid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional HotixReservationStatus status = 20;
 * @return {!proto.commUnity.HotixReservationStatus}
 */
proto.commUnity.HotixReservationDetail.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.HotixReservationStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.HotixReservationStatus} value
 * @return {!proto.commUnity.HotixReservationDetail} returns this
 */
proto.commUnity.HotixReservationDetail.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional HotixReservationDetailResp HotixReservationDetail = 25;
 * @return {?proto.commUnity.HotixReservationDetailResp}
 */
proto.commUnity.HotixReservationDetail.prototype.getHotixreservationdetail = function() {
  return /** @type{?proto.commUnity.HotixReservationDetailResp} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.HotixReservationDetailResp, 25));
};


/**
 * @param {?proto.commUnity.HotixReservationDetailResp|undefined} value
 * @return {!proto.commUnity.HotixReservationDetail} returns this
*/
proto.commUnity.HotixReservationDetail.prototype.setHotixreservationdetail = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.HotixReservationDetail} returns this
 */
proto.commUnity.HotixReservationDetail.prototype.clearHotixreservationdetail = function() {
  return this.setHotixreservationdetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.HotixReservationDetail.prototype.hasHotixreservationdetail = function() {
  return jspb.Message.getField(this, 25) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixPaxDetailResp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixPaxDetailResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixPaxDetailResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixPaxDetailResp.toObject = function(includeInstance, msg) {
  var f, obj = {
civilityid: jspb.Message.getFieldWithDefault(msg, 1, 0),
civilityname: jspb.Message.getFieldWithDefault(msg, 5, ""),
clientaddress: jspb.Message.getFieldWithDefault(msg, 10, ""),
clientbirthdate: jspb.Message.getFieldWithDefault(msg, 15, 0),
clientemail: jspb.Message.getFieldWithDefault(msg, 20, ""),
clientid: jspb.Message.getFieldWithDefault(msg, 25, 0),
clientnationality: jspb.Message.getFieldWithDefault(msg, 30, ""),
clientfirstname: jspb.Message.getFieldWithDefault(msg, 35, ""),
clientlastname: jspb.Message.getFieldWithDefault(msg, 40, ""),
clientphone: jspb.Message.getFieldWithDefault(msg, 45, ""),
ismaster: jspb.Message.getBooleanFieldWithDefault(msg, 50, false),
countryprefixtel: jspb.Message.getFieldWithDefault(msg, 55, ""),
clientsignature: jspb.Message.getFieldWithDefault(msg, 60, ""),
clientsignaturedate: jspb.Message.getFieldWithDefault(msg, 65, 0),
identitydocumentid: jspb.Message.getFieldWithDefault(msg, 70, 0),
identitydocumentlabel: jspb.Message.getFieldWithDefault(msg, 75, ""),
identitydocumentnumber: jspb.Message.getFieldWithDefault(msg, 80, ""),
identitydocumentimage: jspb.Message.getFieldWithDefault(msg, 85, ""),
identitydocumentissdate: jspb.Message.getFieldWithDefault(msg, 90, 0),
identitydocumentexpdate: jspb.Message.getFieldWithDefault(msg, 95, 0),
identitydocumentplace: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixPaxDetailResp}
 */
proto.commUnity.HotixPaxDetailResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixPaxDetailResp;
  return proto.commUnity.HotixPaxDetailResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixPaxDetailResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixPaxDetailResp}
 */
proto.commUnity.HotixPaxDetailResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCivilityid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCivilityname(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientaddress(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClientbirthdate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientemail(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClientid(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientnationality(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientfirstname(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientlastname(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientphone(value);
      break;
    case 50:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsmaster(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryprefixtel(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientsignature(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClientsignaturedate(value);
      break;
    case 70:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdentitydocumentid(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocumentlabel(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocumentnumber(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocumentimage(value);
      break;
    case 90:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdentitydocumentissdate(value);
      break;
    case 95:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdentitydocumentexpdate(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocumentplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixPaxDetailResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixPaxDetailResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixPaxDetailResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixPaxDetailResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCivilityid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCivilityname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientaddress();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getClientbirthdate();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getClientemail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getClientid();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getClientnationality();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getClientfirstname();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getClientlastname();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getClientphone();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getIsmaster();
  if (f) {
    writer.writeBool(
      50,
      f
    );
  }
  f = message.getCountryprefixtel();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getClientsignature();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getClientsignaturedate();
  if (f !== 0) {
    writer.writeInt64(
      65,
      f
    );
  }
  f = message.getIdentitydocumentid();
  if (f !== 0) {
    writer.writeInt64(
      70,
      f
    );
  }
  f = message.getIdentitydocumentlabel();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getIdentitydocumentnumber();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getIdentitydocumentimage();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getIdentitydocumentissdate();
  if (f !== 0) {
    writer.writeInt64(
      90,
      f
    );
  }
  f = message.getIdentitydocumentexpdate();
  if (f !== 0) {
    writer.writeInt64(
      95,
      f
    );
  }
  f = message.getIdentitydocumentplace();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional int64 CivilityID = 1;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getCivilityid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setCivilityid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Civilityname = 5;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getCivilityname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setCivilityname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ClientAddress = 10;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 ClientBirthDate = 15;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientbirthdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientbirthdate = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string ClientEmail = 20;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientemail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientemail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional int64 ClientID = 25;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientid = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string ClientNationality = 30;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientnationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientnationality = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string ClientFirstName = 35;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientfirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientfirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string ClientLastName = 40;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientlastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientlastname = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string ClientPhone = 45;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientphone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientphone = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional bool IsMaster = 50;
 * @return {boolean}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIsmaster = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 50, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIsmaster = function(value) {
  return jspb.Message.setProto3BooleanField(this, 50, value);
};


/**
 * optional string CountryPrefixTel = 55;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getCountryprefixtel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setCountryprefixtel = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string ClientSignature = 60;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientsignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientsignature = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional int64 ClientSignatureDate = 65;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getClientsignaturedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setClientsignaturedate = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


/**
 * optional int64 IdentityDocumentID = 70;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 70, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentid = function(value) {
  return jspb.Message.setProto3IntField(this, 70, value);
};


/**
 * optional string IdentityDocumentLabel = 75;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentlabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentlabel = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional string IdentityDocumentNumber = 80;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string IdentityDocumentImage = 85;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentimage = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional int64 IdentityDocumentIssDate = 90;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentissdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 90, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentissdate = function(value) {
  return jspb.Message.setProto3IntField(this, 90, value);
};


/**
 * optional int64 IdentityDocumentExpDate = 95;
 * @return {number}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentexpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 95, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentexpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 95, value);
};


/**
 * optional string IdentityDocumentPlace = 100;
 * @return {string}
 */
proto.commUnity.HotixPaxDetailResp.prototype.getIdentitydocumentplace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPaxDetailResp} returns this
 */
proto.commUnity.HotixPaxDetailResp.prototype.setIdentitydocumentplace = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.HotixReservationDetailResp.repeatedFields_ = [80];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixReservationDetailResp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixReservationDetailResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixReservationDetailResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservationDetailResp.toObject = function(includeInstance, msg) {
  var f, obj = {
arrangement: jspb.Message.getFieldWithDefault(msg, 1, ""),
roomnumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
arrivaldate: jspb.Message.getFieldWithDefault(msg, 10, 0),
departuredate: jspb.Message.getFieldWithDefault(msg, 15, 0),
billyear: jspb.Message.getFieldWithDefault(msg, 20, 0),
billid: jspb.Message.getFieldWithDefault(msg, 25, 0),
numadults: jspb.Message.getFieldWithDefault(msg, 30, 0),
numbabies: jspb.Message.getFieldWithDefault(msg, 35, 0),
numkids: jspb.Message.getFieldWithDefault(msg, 40, 0),
resaid: jspb.Message.getFieldWithDefault(msg, 45, ""),
roomlistgenerated: jspb.Message.getFieldWithDefault(msg, 50, 0),
resapaxid: jspb.Message.getFieldWithDefault(msg, 55, 0),
resagroupid: jspb.Message.getFieldWithDefault(msg, 60, 0),
company: jspb.Message.getFieldWithDefault(msg, 65, ""),
rates: jspb.Message.getFieldWithDefault(msg, 70, ""),
roomtype: jspb.Message.getFieldWithDefault(msg, 75, ""),
paxdetailsList: jspb.Message.toObjectList(msg.getPaxdetailsList(),
    proto.commUnity.HotixPaxDetailResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixReservationDetailResp}
 */
proto.commUnity.HotixReservationDetailResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixReservationDetailResp;
  return proto.commUnity.HotixReservationDetailResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixReservationDetailResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixReservationDetailResp}
 */
proto.commUnity.HotixReservationDetailResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrangement(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomnumber(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivaldate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeparturedate(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillyear(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBillid(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumadults(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumbabies(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumkids(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setResaid(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRoomlistgenerated(value);
      break;
    case 55:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResapaxid(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResagroupid(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setRates(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 80:
      var value = new proto.commUnity.HotixPaxDetailResp;
      reader.readMessage(value,proto.commUnity.HotixPaxDetailResp.deserializeBinaryFromReader);
      msg.addPaxdetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixReservationDetailResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixReservationDetailResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixReservationDetailResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixReservationDetailResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getArrangement();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomnumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivaldate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDeparturedate();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getBillyear();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getBillid();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getNumadults();
  if (f !== 0) {
    writer.writeInt64(
      30,
      f
    );
  }
  f = message.getNumbabies();
  if (f !== 0) {
    writer.writeInt64(
      35,
      f
    );
  }
  f = message.getNumkids();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getResaid();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getRoomlistgenerated();
  if (f !== 0) {
    writer.writeInt64(
      50,
      f
    );
  }
  f = message.getResapaxid();
  if (f !== 0) {
    writer.writeInt64(
      55,
      f
    );
  }
  f = message.getResagroupid();
  if (f !== 0) {
    writer.writeInt64(
      60,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getRates();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getPaxdetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      80,
      f,
      proto.commUnity.HotixPaxDetailResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string Arrangement = 1;
 * @return {string}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getArrangement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setArrangement = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string RoomNumber = 5;
 * @return {string}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getRoomnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setRoomnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 ArrivalDate = 10;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getArrivaldate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setArrivaldate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 DepartureDate = 15;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getDeparturedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setDeparturedate = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 BillYear = 20;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getBillyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setBillyear = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int64 BillID = 25;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getBillid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setBillid = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int64 NumAdults = 30;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getNumadults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setNumadults = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional int64 NumBabies = 35;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getNumbabies = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setNumbabies = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional int64 NumKids = 40;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getNumkids = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setNumkids = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional string ResaID = 45;
 * @return {string}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getResaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setResaid = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional int64 RoomListGenerated = 50;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getRoomlistgenerated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setRoomlistgenerated = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional int64 ResaPaxID = 55;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getResapaxid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 55, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setResapaxid = function(value) {
  return jspb.Message.setProto3IntField(this, 55, value);
};


/**
 * optional int64 ResaGroupID = 60;
 * @return {number}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getResagroupid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setResagroupid = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional string Company = 65;
 * @return {string}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional string Rates = 70;
 * @return {string}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getRates = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setRates = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string RoomType = 75;
 * @return {string}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * repeated HotixPaxDetailResp PaxDetails = 80;
 * @return {!Array<!proto.commUnity.HotixPaxDetailResp>}
 */
proto.commUnity.HotixReservationDetailResp.prototype.getPaxdetailsList = function() {
  return /** @type{!Array<!proto.commUnity.HotixPaxDetailResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.HotixPaxDetailResp, 80));
};


/**
 * @param {!Array<!proto.commUnity.HotixPaxDetailResp>} value
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
*/
proto.commUnity.HotixReservationDetailResp.prototype.setPaxdetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 80, value);
};


/**
 * @param {!proto.commUnity.HotixPaxDetailResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.HotixPaxDetailResp}
 */
proto.commUnity.HotixReservationDetailResp.prototype.addPaxdetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 80, opt_value, proto.commUnity.HotixPaxDetailResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.HotixReservationDetailResp} returns this
 */
proto.commUnity.HotixReservationDetailResp.prototype.clearPaxdetailsList = function() {
  return this.setPaxdetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixBillLineResp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixBillLineResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixBillLineResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixBillLineResp.toObject = function(includeInstance, msg) {
  var f, obj = {
number: jspb.Message.getFieldWithDefault(msg, 1, ""),
quantity: jspb.Message.getFieldWithDefault(msg, 5, ""),
comment: jspb.Message.getFieldWithDefault(msg, 10, ""),
description: jspb.Message.getFieldWithDefault(msg, 15, ""),
date: jspb.Message.getFieldWithDefault(msg, 20, ""),
hour: jspb.Message.getFieldWithDefault(msg, 25, ""),
price: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
serviceinvoice: jspb.Message.getFieldWithDefault(msg, 35, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixBillLineResp}
 */
proto.commUnity.HotixBillLineResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixBillLineResp;
  return proto.commUnity.HotixBillLineResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixBillLineResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixBillLineResp}
 */
proto.commUnity.HotixBillLineResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantity(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setHour(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceinvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixBillLineResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixBillLineResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixBillLineResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixBillLineResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getHour();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getServiceinvoice();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
};


/**
 * optional string Number = 1;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Quantity = 5;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Comment = 10;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Description = 15;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Date = 20;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string Hour = 25;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setHour = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional double Price = 30;
 * @return {number}
 */
proto.commUnity.HotixBillLineResp.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional string ServiceInvoice = 35;
 * @return {string}
 */
proto.commUnity.HotixBillLineResp.prototype.getServiceinvoice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixBillLineResp} returns this
 */
proto.commUnity.HotixBillLineResp.prototype.setServiceinvoice = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.HotixRoomBillDetailResp.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixRoomBillDetailResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixRoomBillDetailResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixRoomBillDetailResp.toObject = function(includeInstance, msg) {
  var f, obj = {
billid: jspb.Message.getFieldWithDefault(msg, 1, ""),
billyear: jspb.Message.getFieldWithDefault(msg, 5, ""),
currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
totalbill: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
creationdate: jspb.Message.getFieldWithDefault(msg, 20, ""),
linesList: jspb.Message.toObjectList(msg.getLinesList(),
    proto.commUnity.HotixBillLineResp.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixRoomBillDetailResp}
 */
proto.commUnity.HotixRoomBillDetailResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixRoomBillDetailResp;
  return proto.commUnity.HotixRoomBillDetailResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixRoomBillDetailResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixRoomBillDetailResp}
 */
proto.commUnity.HotixRoomBillDetailResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillyear(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalbill(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreationdate(value);
      break;
    case 25:
      var value = new proto.commUnity.HotixBillLineResp;
      reader.readMessage(value,proto.commUnity.HotixBillLineResp.deserializeBinaryFromReader);
      msg.addLines(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixRoomBillDetailResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixRoomBillDetailResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixRoomBillDetailResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBillid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBillyear();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTotalbill();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getCreationdate();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.commUnity.HotixBillLineResp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string BillID = 1;
 * @return {string}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.getBillid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.setBillid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BillYear = 5;
 * @return {string}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.getBillyear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.setBillyear = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Currency = 10;
 * @return {string}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional double TotalBill = 15;
 * @return {number}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.getTotalbill = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.setTotalbill = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional string CreationDate = 20;
 * @return {string}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.getCreationdate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.setCreationdate = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated HotixBillLineResp Lines = 25;
 * @return {!Array<!proto.commUnity.HotixBillLineResp>}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.getLinesList = function() {
  return /** @type{!Array<!proto.commUnity.HotixBillLineResp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.HotixBillLineResp, 25));
};


/**
 * @param {!Array<!proto.commUnity.HotixBillLineResp>} value
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
*/
proto.commUnity.HotixRoomBillDetailResp.prototype.setLinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.commUnity.HotixBillLineResp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.HotixBillLineResp}
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.addLines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.commUnity.HotixBillLineResp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.HotixRoomBillDetailResp} returns this
 */
proto.commUnity.HotixRoomBillDetailResp.prototype.clearLinesList = function() {
  return this.setLinesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixGuest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixGuest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixGuest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixGuest.toObject = function(includeInstance, msg) {
  var f, obj = {
lastname: jspb.Message.getFieldWithDefault(msg, 1, ""),
firstname: jspb.Message.getFieldWithDefault(msg, 5, ""),
title: jspb.Message.getFieldWithDefault(msg, 10, 0),
mobile: jspb.Message.getFieldWithDefault(msg, 15, ""),
email: jspb.Message.getFieldWithDefault(msg, 20, ""),
nationality: jspb.Message.getFieldWithDefault(msg, 30, ""),
birthday: jspb.Message.getFieldWithDefault(msg, 35, 0),
homeaddress: jspb.Message.getFieldWithDefault(msg, 40, ""),
photo: jspb.Message.getFieldWithDefault(msg, 45, ""),
identitydocumenttype: jspb.Message.getFieldWithDefault(msg, 47, 0),
identitydocument: jspb.Message.getFieldWithDefault(msg, 50, ""),
identitydocumentnumber: jspb.Message.getFieldWithDefault(msg, 55, ""),
identitydocumentissdate: jspb.Message.getFieldWithDefault(msg, 60, 0),
identitydocumentexpdate: jspb.Message.getFieldWithDefault(msg, 65, 0),
identitydocumentissplace: jspb.Message.getFieldWithDefault(msg, 70, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixGuest}
 */
proto.commUnity.HotixGuest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixGuest;
  return proto.commUnity.HotixGuest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixGuest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixGuest}
 */
proto.commUnity.HotixGuest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 10:
      var value = /** @type {!proto.commUnity.Title} */ (reader.readEnum());
      msg.setTitle(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBirthday(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setHomeaddress(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 47:
      var value = /** @type {!proto.commUnity.HotixIdentityDocumentType} */ (reader.readEnum());
      msg.setIdentitydocumenttype(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocument(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocumentnumber(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdentitydocumentissdate(value);
      break;
    case 65:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdentitydocumentexpdate(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentitydocumentissplace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixGuest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixGuest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixGuest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixGuest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getNationality();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getHomeaddress();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getIdentitydocumenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      47,
      f
    );
  }
  f = message.getIdentitydocument();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getIdentitydocumentnumber();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getIdentitydocumentissdate();
  if (f !== 0) {
    writer.writeUint32(
      60,
      f
    );
  }
  f = message.getIdentitydocumentexpdate();
  if (f !== 0) {
    writer.writeUint32(
      65,
      f
    );
  }
  f = message.getIdentitydocumentissplace();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
};


/**
 * optional string LastName = 1;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FirstName = 5;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Title Title = 10;
 * @return {!proto.commUnity.Title}
 */
proto.commUnity.HotixGuest.prototype.getTitle = function() {
  return /** @type {!proto.commUnity.Title} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.commUnity.Title} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string Mobile = 15;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Email = 20;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string Nationality = 30;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setNationality = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional uint32 Birthday = 35;
 * @return {number}
 */
proto.commUnity.HotixGuest.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional string HomeAddress = 40;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getHomeaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setHomeaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string Photo = 45;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional HotixIdentityDocumentType IdentityDocumentType = 47;
 * @return {!proto.commUnity.HotixIdentityDocumentType}
 */
proto.commUnity.HotixGuest.prototype.getIdentitydocumenttype = function() {
  return /** @type {!proto.commUnity.HotixIdentityDocumentType} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {!proto.commUnity.HotixIdentityDocumentType} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setIdentitydocumenttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 47, value);
};


/**
 * optional string IdentityDocument = 50;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getIdentitydocument = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setIdentitydocument = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string IdentityDocumentNumber = 55;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getIdentitydocumentnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setIdentitydocumentnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional uint32 IdentityDocumentIssDate = 60;
 * @return {number}
 */
proto.commUnity.HotixGuest.prototype.getIdentitydocumentissdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setIdentitydocumentissdate = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional uint32 IdentityDocumentExpDate = 65;
 * @return {number}
 */
proto.commUnity.HotixGuest.prototype.getIdentitydocumentexpdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 65, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setIdentitydocumentexpdate = function(value) {
  return jspb.Message.setProto3IntField(this, 65, value);
};


/**
 * optional string IdentityDocumentIssPlace = 70;
 * @return {string}
 */
proto.commUnity.HotixGuest.prototype.getIdentitydocumentissplace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixGuest} returns this
 */
proto.commUnity.HotixGuest.prototype.setIdentitydocumentissplace = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.HotixPreCheckin.repeatedFields_ = [65];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixPreCheckin.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixPreCheckin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixPreCheckin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixPreCheckin.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
createdon: jspb.Message.getFieldWithDefault(msg, 2, 0),
updatedon: jspb.Message.getFieldWithDefault(msg, 3, 0),
memberid: jspb.Message.getFieldWithDefault(msg, 10, ""),
reservationid: jspb.Message.getFieldWithDefault(msg, 15, ""),
status: jspb.Message.getFieldWithDefault(msg, 25, 0),
stayreason: jspb.Message.getFieldWithDefault(msg, 30, 0),
roomtype: jspb.Message.getFieldWithDefault(msg, 35, ""),
numpeople: jspb.Message.getFieldWithDefault(msg, 40, 0),
arrivaldate: jspb.Message.getFieldWithDefault(msg, 45, 0),
departuredate: jspb.Message.getFieldWithDefault(msg, 50, 0),
transfer: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
flightnumber: jspb.Message.getFieldWithDefault(msg, 60, ""),
guestsList: jspb.Message.toObjectList(msg.getGuestsList(),
    proto.commUnity.HotixGuest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixPreCheckin}
 */
proto.commUnity.HotixPreCheckin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixPreCheckin;
  return proto.commUnity.HotixPreCheckin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixPreCheckin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixPreCheckin}
 */
proto.commUnity.HotixPreCheckin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedon(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationid(value);
      break;
    case 25:
      var value = /** @type {!proto.commUnity.HotixPreCheckinStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 30:
      var value = /** @type {!proto.commUnity.HotixStayReason} */ (reader.readEnum());
      msg.setStayreason(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomtype(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumpeople(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setArrivaldate(value);
      break;
    case 50:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDeparturedate(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTransfer(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightnumber(value);
      break;
    case 65:
      var value = new proto.commUnity.HotixGuest;
      reader.readMessage(value,proto.commUnity.HotixGuest.deserializeBinaryFromReader);
      msg.addGuests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixPreCheckin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixPreCheckin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixPreCheckin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixPreCheckin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedon();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedon();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReservationid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
  f = message.getStayreason();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getRoomtype();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getNumpeople();
  if (f !== 0) {
    writer.writeInt64(
      40,
      f
    );
  }
  f = message.getArrivaldate();
  if (f !== 0) {
    writer.writeUint32(
      45,
      f
    );
  }
  f = message.getDeparturedate();
  if (f !== 0) {
    writer.writeUint32(
      50,
      f
    );
  }
  f = message.getTransfer();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getFlightnumber();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getGuestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      65,
      f,
      proto.commUnity.HotixGuest.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.HotixPreCheckin.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedOn = 2;
 * @return {number}
 */
proto.commUnity.HotixPreCheckin.prototype.getCreatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setCreatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 UpdatedOn = 3;
 * @return {number}
 */
proto.commUnity.HotixPreCheckin.prototype.getUpdatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setUpdatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string MemberID = 10;
 * @return {string}
 */
proto.commUnity.HotixPreCheckin.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ReservationID = 15;
 * @return {string}
 */
proto.commUnity.HotixPreCheckin.prototype.getReservationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setReservationid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional HotixPreCheckinStatus Status = 25;
 * @return {!proto.commUnity.HotixPreCheckinStatus}
 */
proto.commUnity.HotixPreCheckin.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.HotixPreCheckinStatus} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.commUnity.HotixPreCheckinStatus} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * optional HotixStayReason StayReason = 30;
 * @return {!proto.commUnity.HotixStayReason}
 */
proto.commUnity.HotixPreCheckin.prototype.getStayreason = function() {
  return /** @type {!proto.commUnity.HotixStayReason} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.commUnity.HotixStayReason} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setStayreason = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * optional string RoomType = 35;
 * @return {string}
 */
proto.commUnity.HotixPreCheckin.prototype.getRoomtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setRoomtype = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional int64 NumPeople = 40;
 * @return {number}
 */
proto.commUnity.HotixPreCheckin.prototype.getNumpeople = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setNumpeople = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional uint32 ArrivalDate = 45;
 * @return {number}
 */
proto.commUnity.HotixPreCheckin.prototype.getArrivaldate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setArrivaldate = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional uint32 DepartureDate = 50;
 * @return {number}
 */
proto.commUnity.HotixPreCheckin.prototype.getDeparturedate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 50, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setDeparturedate = function(value) {
  return jspb.Message.setProto3IntField(this, 50, value);
};


/**
 * optional bool Transfer = 55;
 * @return {boolean}
 */
proto.commUnity.HotixPreCheckin.prototype.getTransfer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setTransfer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional string FlightNumber = 60;
 * @return {string}
 */
proto.commUnity.HotixPreCheckin.prototype.getFlightnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.setFlightnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * repeated HotixGuest Guests = 65;
 * @return {!Array<!proto.commUnity.HotixGuest>}
 */
proto.commUnity.HotixPreCheckin.prototype.getGuestsList = function() {
  return /** @type{!Array<!proto.commUnity.HotixGuest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.HotixGuest, 65));
};


/**
 * @param {!Array<!proto.commUnity.HotixGuest>} value
 * @return {!proto.commUnity.HotixPreCheckin} returns this
*/
proto.commUnity.HotixPreCheckin.prototype.setGuestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 65, value);
};


/**
 * @param {!proto.commUnity.HotixGuest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.HotixGuest}
 */
proto.commUnity.HotixPreCheckin.prototype.addGuests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 65, opt_value, proto.commUnity.HotixGuest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.HotixPreCheckin} returns this
 */
proto.commUnity.HotixPreCheckin.prototype.clearGuestsList = function() {
  return this.setGuestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.AdminHotixSatisfactionSurvey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.AdminHotixSatisfactionSurvey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AdminHotixSatisfactionSurvey.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
createdon: jspb.Message.getFieldWithDefault(msg, 2, 0),
updatedon: jspb.Message.getFieldWithDefault(msg, 3, 0),
memberid: jspb.Message.getFieldWithDefault(msg, 10, ""),
member: (f = msg.getMember()) && commUnity_pb.Member.toObject(includeInstance, f),
reservationid: jspb.Message.getFieldWithDefault(msg, 20, ""),
comment: jspb.Message.getFieldWithDefault(msg, 25, ""),
levelsatisfaction: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.AdminHotixSatisfactionSurvey;
  return proto.commUnity.AdminHotixSatisfactionSurvey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.AdminHotixSatisfactionSurvey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedon(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 15:
      var value = new commUnity_pb.Member;
      reader.readMessage(value,commUnity_pb.Member.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 30:
      var value = /** @type {!proto.commUnity.HotixLevelSatisfaction} */ (reader.readEnum());
      msg.setLevelsatisfaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.AdminHotixSatisfactionSurvey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.AdminHotixSatisfactionSurvey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AdminHotixSatisfactionSurvey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedon();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedon();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      commUnity_pb.Member.serializeBinaryToWriter
    );
  }
  f = message.getReservationid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLevelsatisfaction();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedOn = 2;
 * @return {number}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getCreatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setCreatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 UpdatedOn = 3;
 * @return {number}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getUpdatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setUpdatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string MemberID = 10;
 * @return {string}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Member Member = 15;
 * @return {?proto.commUnity.Member}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getMember = function() {
  return /** @type{?proto.commUnity.Member} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Member, 15));
};


/**
 * @param {?proto.commUnity.Member|undefined} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
*/
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setMember = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.hasMember = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string ReservationID = 20;
 * @return {string}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getReservationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setReservationid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string Comment = 25;
 * @return {string}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional HotixLevelSatisfaction LevelSatisfaction = 30;
 * @return {!proto.commUnity.HotixLevelSatisfaction}
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.getLevelsatisfaction = function() {
  return /** @type {!proto.commUnity.HotixLevelSatisfaction} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.commUnity.HotixLevelSatisfaction} value
 * @return {!proto.commUnity.AdminHotixSatisfactionSurvey} returns this
 */
proto.commUnity.AdminHotixSatisfactionSurvey.prototype.setLevelsatisfaction = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.HotixSatisfactionSurvey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.HotixSatisfactionSurvey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixSatisfactionSurvey.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
createdon: jspb.Message.getFieldWithDefault(msg, 2, 0),
updatedon: jspb.Message.getFieldWithDefault(msg, 3, 0),
memberid: jspb.Message.getFieldWithDefault(msg, 10, ""),
reservationid: jspb.Message.getFieldWithDefault(msg, 15, ""),
comment: jspb.Message.getFieldWithDefault(msg, 20, ""),
levelsatisfaction: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.HotixSatisfactionSurvey}
 */
proto.commUnity.HotixSatisfactionSurvey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.HotixSatisfactionSurvey;
  return proto.commUnity.HotixSatisfactionSurvey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.HotixSatisfactionSurvey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.HotixSatisfactionSurvey}
 */
proto.commUnity.HotixSatisfactionSurvey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedon(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 25:
      var value = /** @type {!proto.commUnity.HotixLevelSatisfaction} */ (reader.readEnum());
      msg.setLevelsatisfaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.HotixSatisfactionSurvey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.HotixSatisfactionSurvey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.HotixSatisfactionSurvey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedon();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedon();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReservationid();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getLevelsatisfaction();
  if (f !== 0.0) {
    writer.writeEnum(
      25,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedOn = 2;
 * @return {number}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getCreatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setCreatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 UpdatedOn = 3;
 * @return {number}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getUpdatedon = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setUpdatedon = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string MemberID = 10;
 * @return {string}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ReservationID = 15;
 * @return {string}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getReservationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setReservationid = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string Comment = 20;
 * @return {string}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional HotixLevelSatisfaction LevelSatisfaction = 25;
 * @return {!proto.commUnity.HotixLevelSatisfaction}
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.getLevelsatisfaction = function() {
  return /** @type {!proto.commUnity.HotixLevelSatisfaction} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {!proto.commUnity.HotixLevelSatisfaction} value
 * @return {!proto.commUnity.HotixSatisfactionSurvey} returns this
 */
proto.commUnity.HotixSatisfactionSurvey.prototype.setLevelsatisfaction = function(value) {
  return jspb.Message.setProto3EnumField(this, 25, value);
};


/**
 * @enum {number}
 */
proto.commUnity.HotixReservationStatus = {
  HOTIXRESERVATIONSTATUS_NEW: 0,
  HOTIXRESERVATIONSTATUS_PENDING_PRE_CHECKIN: 1,
  HOTIXRESERVATIONSTATUS_CONFIRMED: 10,
  HOTIXRESERVATIONSTATUS_CANCELLED: 99
};

/**
 * @enum {number}
 */
proto.commUnity.HotixStayReason = {
  HOTIXSTAYREASON_PROFESSIONAL: 0,
  HOTIXSTAYREASON_HOLIDAY: 10
};

/**
 * @enum {number}
 */
proto.commUnity.HotixPreCheckinStatus = {
  HOTIXPRECHECKINSTATUS_NEW: 0,
  HOTIXPRECHECKINSTATUS_DRAFT: 2,
  HOTIXPRECHECKINSTATUS_CONFIRMED: 4
};

/**
 * @enum {number}
 */
proto.commUnity.HotixLevelSatisfaction = {
  HOTIXLEVELSATISFACTION_VERY_SATISFIED: 0,
  HOTIXLEVELSATISFACTION_SATISFIED: 2,
  HOTIXLEVELSATISFACTION_DISSATISFIED: 4,
  HOTIXLEVELSATISFACTION_VERY_DISSATISFIED: 6
};

/**
 * @enum {number}
 */
proto.commUnity.HotixIdentityDocumentType = {
  HOTIXIDENTITYDOCUMENTTYPE_ID_CARD: 0,
  HOTIXIDENTITYDOCUMENTTYPE_PASSPORT: 2,
  HOTIXIDENTITYDOCUMENTTYPE_RESIDENCE_PERMIT: 4,
  HOTIXIDENTITYDOCUMENTTYPE_DRIVING_LICENSE: 6
};

goog.object.extend(exports, proto.commUnity);
