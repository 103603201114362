// source: shop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var commUnity_pb = require('./commUnity_pb.js');
goog.object.extend(proto, commUnity_pb);
goog.exportSymbol('proto.commUnity.AboutUs', null, global);
goog.exportSymbol('proto.commUnity.Cart', null, global);
goog.exportSymbol('proto.commUnity.CheckOut', null, global);
goog.exportSymbol('proto.commUnity.CheckPromoCode', null, global);
goog.exportSymbol('proto.commUnity.DeliveryAddress', null, global);
goog.exportSymbol('proto.commUnity.DeliveryMethod', null, global);
goog.exportSymbol('proto.commUnity.Discount', null, global);
goog.exportSymbol('proto.commUnity.DiscountOn', null, global);
goog.exportSymbol('proto.commUnity.OrderInfo', null, global);
goog.exportSymbol('proto.commUnity.OrderStatus', null, global);
goog.exportSymbol('proto.commUnity.PaymentStatus', null, global);
goog.exportSymbol('proto.commUnity.Product', null, global);
goog.exportSymbol('proto.commUnity.ProductCategory', null, global);
goog.exportSymbol('proto.commUnity.ProductOrder', null, global);
goog.exportSymbol('proto.commUnity.ProductOrderLine', null, global);
goog.exportSymbol('proto.commUnity.PromoStatus', null, global);
goog.exportSymbol('proto.commUnity.Promotion', null, global);
goog.exportSymbol('proto.commUnity.PromotionType', null, global);
goog.exportSymbol('proto.commUnity.ShippingCost', null, global);
goog.exportSymbol('proto.commUnity.ShopSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ProductCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ProductCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ProductCategory.displayName = 'proto.commUnity.ProductCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ShopSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ShopSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ShopSettings.displayName = 'proto.commUnity.ShopSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Promotion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Promotion.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Promotion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Promotion.displayName = 'proto.commUnity.Promotion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.AboutUs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.AboutUs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.AboutUs.displayName = 'proto.commUnity.AboutUs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.OrderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.OrderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.OrderInfo.displayName = 'proto.commUnity.OrderInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CheckOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.CheckOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CheckOut.displayName = 'proto.commUnity.CheckOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.DeliveryAddress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.DeliveryAddress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.DeliveryAddress.displayName = 'proto.commUnity.DeliveryAddress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Cart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Cart.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Cart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Cart.displayName = 'proto.commUnity.Cart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ProductOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.ProductOrder.repeatedFields_, null);
};
goog.inherits(proto.commUnity.ProductOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ProductOrder.displayName = 'proto.commUnity.ProductOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ProductOrderLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ProductOrderLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ProductOrderLine.displayName = 'proto.commUnity.ProductOrderLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ShippingCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ShippingCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ShippingCost.displayName = 'proto.commUnity.ShippingCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.Product.repeatedFields_, null);
};
goog.inherits(proto.commUnity.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Product.displayName = 'proto.commUnity.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CheckPromoCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.CheckPromoCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CheckPromoCode.displayName = 'proto.commUnity.CheckPromoCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.Discount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.Discount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.Discount.displayName = 'proto.commUnity.Discount';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ProductCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ProductCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ProductCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
order: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ProductCategory}
 */
proto.commUnity.ProductCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ProductCategory;
  return proto.commUnity.ProductCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ProductCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ProductCategory}
 */
proto.commUnity.ProductCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ProductCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ProductCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ProductCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.ProductCategory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductCategory} returns this
 */
proto.commUnity.ProductCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.commUnity.ProductCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductCategory} returns this
 */
proto.commUnity.ProductCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.commUnity.ProductCategory.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductCategory} returns this
 */
proto.commUnity.ProductCategory.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 Order = 5;
 * @return {number}
 */
proto.commUnity.ProductCategory.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductCategory} returns this
 */
proto.commUnity.ProductCategory.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ShopSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ShopSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ShopSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ShopSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
pickupmessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
mindelaypickup: jspb.Message.getFieldWithDefault(msg, 3, 0),
mindelaydelivery: jspb.Message.getFieldWithDefault(msg, 4, 0),
canschedulepickup: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
canscheduledelivery: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
paypickup: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
defaultcollapse: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
showprice: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
canpickup: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
candeliver: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
emailorder: jspb.Message.getFieldWithDefault(msg, 80, ""),
aboutus: (f = msg.getAboutus()) && proto.commUnity.AboutUs.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ShopSettings}
 */
proto.commUnity.ShopSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ShopSettings;
  return proto.commUnity.ShopSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ShopSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ShopSettings}
 */
proto.commUnity.ShopSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupmessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMindelaypickup(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMindelaydelivery(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanschedulepickup(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanscheduledelivery(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPaypickup(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultcollapse(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShowprice(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanpickup(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCandeliver(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailorder(value);
      break;
    case 100:
      var value = new proto.commUnity.AboutUs;
      reader.readMessage(value,proto.commUnity.AboutUs.deserializeBinaryFromReader);
      msg.setAboutus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ShopSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ShopSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ShopSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ShopSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPickupmessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMindelaypickup();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMindelaydelivery();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCanschedulepickup();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getCanscheduledelivery();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getPaypickup();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getDefaultcollapse();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getShowprice();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCanpickup();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getCandeliver();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getEmailorder();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getAboutus();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.commUnity.AboutUs.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.ShopSettings.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string PickupMessage = 2;
 * @return {string}
 */
proto.commUnity.ShopSettings.prototype.getPickupmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setPickupmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 MinDelayPickup = 3;
 * @return {number}
 */
proto.commUnity.ShopSettings.prototype.getMindelaypickup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setMindelaypickup = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 MinDelayDelivery = 4;
 * @return {number}
 */
proto.commUnity.ShopSettings.prototype.getMindelaydelivery = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setMindelaydelivery = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool CanSchedulePickup = 30;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getCanschedulepickup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setCanschedulepickup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool CanScheduleDelivery = 31;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getCanscheduledelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setCanscheduledelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool PayPickup = 32;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getPaypickup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setPaypickup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool DefaultCollapse = 33;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getDefaultcollapse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setDefaultcollapse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool ShowPrice = 34;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getShowprice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setShowprice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool CanPickup = 35;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getCanpickup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setCanpickup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool CanDeliver = 36;
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.getCandeliver = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setCandeliver = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional string EmailOrder = 80;
 * @return {string}
 */
proto.commUnity.ShopSettings.prototype.getEmailorder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.setEmailorder = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional AboutUs AboutUs = 100;
 * @return {?proto.commUnity.AboutUs}
 */
proto.commUnity.ShopSettings.prototype.getAboutus = function() {
  return /** @type{?proto.commUnity.AboutUs} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.AboutUs, 100));
};


/**
 * @param {?proto.commUnity.AboutUs|undefined} value
 * @return {!proto.commUnity.ShopSettings} returns this
*/
proto.commUnity.ShopSettings.prototype.setAboutus = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.ShopSettings} returns this
 */
proto.commUnity.ShopSettings.prototype.clearAboutus = function() {
  return this.setAboutus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.ShopSettings.prototype.hasAboutus = function() {
  return jspb.Message.getField(this, 100) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Promotion.repeatedFields_ = [50,60];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Promotion.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Promotion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Promotion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Promotion.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
code: jspb.Message.getFieldWithDefault(msg, 2, ""),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
maxuse: jspb.Message.getFieldWithDefault(msg, 4, 0),
publishdate: jspb.Message.getFieldWithDefault(msg, 5, 0),
endpublishdate: jspb.Message.getFieldWithDefault(msg, 8, 0),
shortdescription: jspb.Message.getFieldWithDefault(msg, 10, ""),
description: jspb.Message.getFieldWithDefault(msg, 15, ""),
type: jspb.Message.getFieldWithDefault(msg, 20, 0),
status: jspb.Message.getFieldWithDefault(msg, 21, 0),
minimumpurchase: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
discountamount: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
discountpct: jspb.Message.getFieldWithDefault(msg, 35, 0),
discounton: jspb.Message.getFieldWithDefault(msg, 40, 0),
productsList: (f = jspb.Message.getRepeatedField(msg, 50)) == null ? undefined : f,
categoriesList: (f = jspb.Message.getRepeatedField(msg, 60)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Promotion}
 */
proto.commUnity.Promotion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Promotion;
  return proto.commUnity.Promotion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Promotion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Promotion}
 */
proto.commUnity.Promotion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxuse(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPublishdate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEndpublishdate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.PromotionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 21:
      var value = /** @type {!proto.commUnity.PromoStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinimumpurchase(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiscountamount(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDiscountpct(value);
      break;
    case 40:
      var value = /** @type {!proto.commUnity.DiscountOn} */ (reader.readEnum());
      msg.setDiscounton(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.addProducts(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Promotion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Promotion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Promotion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Promotion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMaxuse();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPublishdate();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getEndpublishdate();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getMinimumpurchase();
  if (f !== 0.0) {
    writer.writeFloat(
      25,
      f
    );
  }
  f = message.getDiscountamount();
  if (f !== 0.0) {
    writer.writeFloat(
      30,
      f
    );
  }
  f = message.getDiscountpct();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getDiscounton();
  if (f !== 0.0) {
    writer.writeEnum(
      40,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      50,
      f
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      60,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Promotion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Code = 2;
 * @return {string}
 */
proto.commUnity.Promotion.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Name = 3;
 * @return {string}
 */
proto.commUnity.Promotion.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 MaxUse = 4;
 * @return {number}
 */
proto.commUnity.Promotion.prototype.getMaxuse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setMaxuse = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 PublishDate = 5;
 * @return {number}
 */
proto.commUnity.Promotion.prototype.getPublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setPublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 EndPublishDate = 8;
 * @return {number}
 */
proto.commUnity.Promotion.prototype.getEndpublishdate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setEndpublishdate = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string ShortDescription = 10;
 * @return {string}
 */
proto.commUnity.Promotion.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string Description = 15;
 * @return {string}
 */
proto.commUnity.Promotion.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional PromotionType Type = 20;
 * @return {!proto.commUnity.PromotionType}
 */
proto.commUnity.Promotion.prototype.getType = function() {
  return /** @type {!proto.commUnity.PromotionType} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.PromotionType} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional PromoStatus Status = 21;
 * @return {!proto.commUnity.PromoStatus}
 */
proto.commUnity.Promotion.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.PromoStatus} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.commUnity.PromoStatus} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional float MinimumPurchase = 25;
 * @return {number}
 */
proto.commUnity.Promotion.prototype.getMinimumpurchase = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setMinimumpurchase = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional float DiscountAmount = 30;
 * @return {number}
 */
proto.commUnity.Promotion.prototype.getDiscountamount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setDiscountamount = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional uint32 DiscountPct = 35;
 * @return {number}
 */
proto.commUnity.Promotion.prototype.getDiscountpct = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setDiscountpct = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional DiscountOn DiscountOn = 40;
 * @return {!proto.commUnity.DiscountOn}
 */
proto.commUnity.Promotion.prototype.getDiscounton = function() {
  return /** @type {!proto.commUnity.DiscountOn} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {!proto.commUnity.DiscountOn} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setDiscounton = function(value) {
  return jspb.Message.setProto3EnumField(this, 40, value);
};


/**
 * repeated string Products = 50;
 * @return {!Array<string>}
 */
proto.commUnity.Promotion.prototype.getProductsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 50));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setProductsList = function(value) {
  return jspb.Message.setField(this, 50, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.addProducts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 50, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * repeated string Categories = 60;
 * @return {!Array<string>}
 */
proto.commUnity.Promotion.prototype.getCategoriesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 60));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.setCategoriesList = function(value) {
  return jspb.Message.setField(this, 60, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.addCategories = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 60, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Promotion} returns this
 */
proto.commUnity.Promotion.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.AboutUs.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.AboutUs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.AboutUs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AboutUs.toObject = function(includeInstance, msg) {
  var f, obj = {
title: jspb.Message.getFieldWithDefault(msg, 1, ""),
shortdescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
placeid: jspb.Message.getFieldWithDefault(msg, 4, ""),
photo: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.AboutUs}
 */
proto.commUnity.AboutUs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.AboutUs;
  return proto.commUnity.AboutUs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.AboutUs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.AboutUs}
 */
proto.commUnity.AboutUs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.AboutUs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.AboutUs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.AboutUs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.AboutUs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlaceid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string Title = 1;
 * @return {string}
 */
proto.commUnity.AboutUs.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AboutUs} returns this
 */
proto.commUnity.AboutUs.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ShortDescription = 2;
 * @return {string}
 */
proto.commUnity.AboutUs.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AboutUs} returns this
 */
proto.commUnity.AboutUs.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Description = 3;
 * @return {string}
 */
proto.commUnity.AboutUs.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AboutUs} returns this
 */
proto.commUnity.AboutUs.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string PlaceID = 4;
 * @return {string}
 */
proto.commUnity.AboutUs.prototype.getPlaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AboutUs} returns this
 */
proto.commUnity.AboutUs.prototype.setPlaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Photo = 10;
 * @return {string}
 */
proto.commUnity.AboutUs.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.AboutUs} returns this
 */
proto.commUnity.AboutUs.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.OrderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.OrderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.OrderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.OrderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
orderid: jspb.Message.getFieldWithDefault(msg, 1, ""),
ordernumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
paymenturl: jspb.Message.getFieldWithDefault(msg, 3, ""),
shippingcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
status: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.OrderInfo}
 */
proto.commUnity.OrderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.OrderInfo;
  return proto.commUnity.OrderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.OrderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.OrderInfo}
 */
proto.commUnity.OrderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymenturl(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShippingcost(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiscount(value);
      break;
    case 11:
      var value = /** @type {!proto.commUnity.OrderStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.OrderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.OrderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.OrderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.OrderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPaymenturl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShippingcost();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string OrderID = 1;
 * @return {string}
 */
proto.commUnity.OrderInfo.prototype.getOrderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.OrderInfo} returns this
 */
proto.commUnity.OrderInfo.prototype.setOrderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrderNumber = 2;
 * @return {string}
 */
proto.commUnity.OrderInfo.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.OrderInfo} returns this
 */
proto.commUnity.OrderInfo.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string PaymentURL = 3;
 * @return {string}
 */
proto.commUnity.OrderInfo.prototype.getPaymenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.OrderInfo} returns this
 */
proto.commUnity.OrderInfo.prototype.setPaymenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float ShippingCost = 4;
 * @return {number}
 */
proto.commUnity.OrderInfo.prototype.getShippingcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.OrderInfo} returns this
 */
proto.commUnity.OrderInfo.prototype.setShippingcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float Discount = 5;
 * @return {number}
 */
proto.commUnity.OrderInfo.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.OrderInfo} returns this
 */
proto.commUnity.OrderInfo.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional OrderStatus Status = 11;
 * @return {!proto.commUnity.OrderStatus}
 */
proto.commUnity.OrderInfo.prototype.getStatus = function() {
  return /** @type {!proto.commUnity.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.commUnity.OrderStatus} value
 * @return {!proto.commUnity.OrderInfo} returns this
 */
proto.commUnity.OrderInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CheckOut.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CheckOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CheckOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckOut.toObject = function(includeInstance, msg) {
  var f, obj = {
deliverymethod: jspb.Message.getFieldWithDefault(msg, 1, 0),
deliveryaddress: (f = msg.getDeliveryaddress()) && proto.commUnity.DeliveryAddress.toObject(includeInstance, f),
couponcode: jspb.Message.getFieldWithDefault(msg, 16, ""),
scheduleddeliverydate: jspb.Message.getFieldWithDefault(msg, 28, 0),
note: jspb.Message.getFieldWithDefault(msg, 31, ""),
urlok: jspb.Message.getFieldWithDefault(msg, 50, ""),
urlnotok: jspb.Message.getFieldWithDefault(msg, 51, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CheckOut}
 */
proto.commUnity.CheckOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CheckOut;
  return proto.commUnity.CheckOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CheckOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CheckOut}
 */
proto.commUnity.CheckOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.commUnity.DeliveryMethod} */ (reader.readEnum());
      msg.setDeliverymethod(value);
      break;
    case 3:
      var value = new proto.commUnity.DeliveryAddress;
      reader.readMessage(value,proto.commUnity.DeliveryAddress.deserializeBinaryFromReader);
      msg.setDeliveryaddress(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponcode(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduleddeliverydate(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlok(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlnotok(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CheckOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CheckOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CheckOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliverymethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDeliveryaddress();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.commUnity.DeliveryAddress.serializeBinaryToWriter
    );
  }
  f = message.getCouponcode();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getScheduleddeliverydate();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getUrlok();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getUrlnotok();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
};


/**
 * optional DeliveryMethod DeliveryMethod = 1;
 * @return {!proto.commUnity.DeliveryMethod}
 */
proto.commUnity.CheckOut.prototype.getDeliverymethod = function() {
  return /** @type {!proto.commUnity.DeliveryMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.commUnity.DeliveryMethod} value
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.setDeliverymethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional DeliveryAddress DeliveryAddress = 3;
 * @return {?proto.commUnity.DeliveryAddress}
 */
proto.commUnity.CheckOut.prototype.getDeliveryaddress = function() {
  return /** @type{?proto.commUnity.DeliveryAddress} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.DeliveryAddress, 3));
};


/**
 * @param {?proto.commUnity.DeliveryAddress|undefined} value
 * @return {!proto.commUnity.CheckOut} returns this
*/
proto.commUnity.CheckOut.prototype.setDeliveryaddress = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.clearDeliveryaddress = function() {
  return this.setDeliveryaddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.CheckOut.prototype.hasDeliveryaddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string CouponCode = 16;
 * @return {string}
 */
proto.commUnity.CheckOut.prototype.getCouponcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.setCouponcode = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 ScheduledDeliveryDate = 28;
 * @return {number}
 */
proto.commUnity.CheckOut.prototype.getScheduleddeliverydate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.setScheduleddeliverydate = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional string Note = 31;
 * @return {string}
 */
proto.commUnity.CheckOut.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string URLOK = 50;
 * @return {string}
 */
proto.commUnity.CheckOut.prototype.getUrlok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.setUrlok = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string URLNOTOK = 51;
 * @return {string}
 */
proto.commUnity.CheckOut.prototype.getUrlnotok = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckOut} returns this
 */
proto.commUnity.CheckOut.prototype.setUrlnotok = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.DeliveryAddress.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.DeliveryAddress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.DeliveryAddress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.DeliveryAddress.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
address: (f = msg.getAddress()) && commUnity_pb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.DeliveryAddress}
 */
proto.commUnity.DeliveryAddress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.DeliveryAddress;
  return proto.commUnity.DeliveryAddress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.DeliveryAddress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.DeliveryAddress}
 */
proto.commUnity.DeliveryAddress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new commUnity_pb.Address;
      reader.readMessage(value,commUnity_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.DeliveryAddress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.DeliveryAddress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.DeliveryAddress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.DeliveryAddress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      commUnity_pb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.DeliveryAddress.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.DeliveryAddress} returns this
 */
proto.commUnity.DeliveryAddress.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.DeliveryAddress.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.DeliveryAddress} returns this
 */
proto.commUnity.DeliveryAddress.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Address Address = 5;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.DeliveryAddress.prototype.getAddress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Address, 5));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.DeliveryAddress} returns this
*/
proto.commUnity.DeliveryAddress.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.DeliveryAddress} returns this
 */
proto.commUnity.DeliveryAddress.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.DeliveryAddress.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Cart.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Cart.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Cart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Cart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Cart.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.commUnity.ProductOrderLine.toObject, includeInstance),
shippingcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
deliverymethod: jspb.Message.getFieldWithDefault(msg, 21, 0),
totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
totalweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
nbitems: jspb.Message.getFieldWithDefault(msg, 24, 0),
note: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Cart}
 */
proto.commUnity.Cart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Cart;
  return proto.commUnity.Cart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Cart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Cart}
 */
proto.commUnity.Cart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = new proto.commUnity.ProductOrderLine;
      reader.readMessage(value,proto.commUnity.ProductOrderLine.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShippingcost(value);
      break;
    case 21:
      var value = /** @type {!proto.commUnity.DeliveryMethod} */ (reader.readEnum());
      msg.setDeliverymethod(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalweight(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNbitems(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Cart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Cart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Cart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Cart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.commUnity.ProductOrderLine.serializeBinaryToWriter
    );
  }
  f = message.getShippingcost();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getDeliverymethod();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getTotalweight();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getNbitems();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Cart.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ProductOrderLine Products = 10;
 * @return {!Array<!proto.commUnity.ProductOrderLine>}
 */
proto.commUnity.Cart.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.commUnity.ProductOrderLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.ProductOrderLine, 10));
};


/**
 * @param {!Array<!proto.commUnity.ProductOrderLine>} value
 * @return {!proto.commUnity.Cart} returns this
*/
proto.commUnity.Cart.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.commUnity.ProductOrderLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.ProductOrderLine}
 */
proto.commUnity.Cart.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.commUnity.ProductOrderLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional float ShippingCost = 20;
 * @return {number}
 */
proto.commUnity.Cart.prototype.getShippingcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setShippingcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional DeliveryMethod DeliveryMethod = 21;
 * @return {!proto.commUnity.DeliveryMethod}
 */
proto.commUnity.Cart.prototype.getDeliverymethod = function() {
  return /** @type {!proto.commUnity.DeliveryMethod} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.commUnity.DeliveryMethod} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setDeliverymethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional double TotalPrice = 22;
 * @return {number}
 */
proto.commUnity.Cart.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double TotalWeight = 23;
 * @return {number}
 */
proto.commUnity.Cart.prototype.getTotalweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setTotalweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional uint32 NbItems = 24;
 * @return {number}
 */
proto.commUnity.Cart.prototype.getNbitems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setNbitems = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string Note = 31;
 * @return {string}
 */
proto.commUnity.Cart.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Cart} returns this
 */
proto.commUnity.Cart.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.ProductOrder.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ProductOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ProductOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ProductOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
ordernumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
memberid: jspb.Message.getFieldWithDefault(msg, 3, ""),
membername: jspb.Message.getFieldWithDefault(msg, 4, ""),
orderdatetime: jspb.Message.getFieldWithDefault(msg, 5, 0),
productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.commUnity.ProductOrderLine.toObject, includeInstance),
orderstatus: jspb.Message.getFieldWithDefault(msg, 11, 0),
paymentstatus: jspb.Message.getFieldWithDefault(msg, 12, 0),
paymenttimestamp: jspb.Message.getFieldWithDefault(msg, 14, 0),
discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
promotionid: jspb.Message.getFieldWithDefault(msg, 16, ""),
promotioncode: jspb.Message.getFieldWithDefault(msg, 17, ""),
shippingcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
deliverymethod: jspb.Message.getFieldWithDefault(msg, 21, 0),
totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
totalweight: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
nbitems: jspb.Message.getFieldWithDefault(msg, 24, 0),
paymentref: jspb.Message.getFieldWithDefault(msg, 25, ""),
deliverytracking: jspb.Message.getFieldWithDefault(msg, 26, ""),
paymenturl: jspb.Message.getFieldWithDefault(msg, 27, ""),
scheduleddeliverydate: jspb.Message.getFieldWithDefault(msg, 28, 0),
deliveryaddress: (f = msg.getDeliveryaddress()) && proto.commUnity.DeliveryAddress.toObject(includeInstance, f),
note: jspb.Message.getFieldWithDefault(msg, 31, ""),
pickupplaceid: jspb.Message.getFieldWithDefault(msg, 40, ""),
pickupplacename: jspb.Message.getFieldWithDefault(msg, 41, ""),
canceltimestamp: jspb.Message.getFieldWithDefault(msg, 100, 0),
cancelreason: jspb.Message.getFieldWithDefault(msg, 101, ""),
deliveredtimestamp: jspb.Message.getFieldWithDefault(msg, 110, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ProductOrder}
 */
proto.commUnity.ProductOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ProductOrder;
  return proto.commUnity.ProductOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ProductOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ProductOrder}
 */
proto.commUnity.ProductOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrdernumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMemberid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMembername(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderdatetime(value);
      break;
    case 10:
      var value = new proto.commUnity.ProductOrderLine;
      reader.readMessage(value,proto.commUnity.ProductOrderLine.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 11:
      var value = /** @type {!proto.commUnity.OrderStatus} */ (reader.readEnum());
      msg.setOrderstatus(value);
      break;
    case 12:
      var value = /** @type {!proto.commUnity.PaymentStatus} */ (reader.readEnum());
      msg.setPaymentstatus(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaymenttimestamp(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiscount(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotionid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromotioncode(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShippingcost(value);
      break;
    case 21:
      var value = /** @type {!proto.commUnity.DeliveryMethod} */ (reader.readEnum());
      msg.setDeliverymethod(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalweight(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNbitems(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymentref(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliverytracking(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPaymenturl(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduleddeliverydate(value);
      break;
    case 30:
      var value = new proto.commUnity.DeliveryAddress;
      reader.readMessage(value,proto.commUnity.DeliveryAddress.deserializeBinaryFromReader);
      msg.setDeliveryaddress(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupplaceid(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setPickupplacename(value);
      break;
    case 100:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCanceltimestamp(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelreason(value);
      break;
    case 110:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeliveredtimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ProductOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ProductOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ProductOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrdernumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMemberid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMembername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderdatetime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.commUnity.ProductOrderLine.serializeBinaryToWriter
    );
  }
  f = message.getOrderstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getPaymentstatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getPaymenttimestamp();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getPromotionid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPromotioncode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getShippingcost();
  if (f !== 0.0) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = message.getDeliverymethod();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getTotalweight();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getNbitems();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getPaymentref();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getDeliverytracking();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPaymenturl();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getScheduleddeliverydate();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getDeliveryaddress();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.commUnity.DeliveryAddress.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getPickupplaceid();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getPickupplacename();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getCanceltimestamp();
  if (f !== 0) {
    writer.writeInt64(
      100,
      f
    );
  }
  f = message.getCancelreason();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getDeliveredtimestamp();
  if (f !== 0) {
    writer.writeInt64(
      110,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OrderNumber = 2;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getOrdernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setOrdernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string MemberID = 3;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getMemberid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setMemberid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string MemberName = 4;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getMembername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setMembername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 OrderDateTime = 5;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getOrderdatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setOrderdatetime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated ProductOrderLine Products = 10;
 * @return {!Array<!proto.commUnity.ProductOrderLine>}
 */
proto.commUnity.ProductOrder.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.commUnity.ProductOrderLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.ProductOrderLine, 10));
};


/**
 * @param {!Array<!proto.commUnity.ProductOrderLine>} value
 * @return {!proto.commUnity.ProductOrder} returns this
*/
proto.commUnity.ProductOrder.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.commUnity.ProductOrderLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.ProductOrderLine}
 */
proto.commUnity.ProductOrder.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.commUnity.ProductOrderLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * optional OrderStatus OrderStatus = 11;
 * @return {!proto.commUnity.OrderStatus}
 */
proto.commUnity.ProductOrder.prototype.getOrderstatus = function() {
  return /** @type {!proto.commUnity.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.commUnity.OrderStatus} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setOrderstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional PaymentStatus PaymentStatus = 12;
 * @return {!proto.commUnity.PaymentStatus}
 */
proto.commUnity.ProductOrder.prototype.getPaymentstatus = function() {
  return /** @type {!proto.commUnity.PaymentStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.commUnity.PaymentStatus} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPaymentstatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional int64 PaymentTimeStamp = 14;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getPaymenttimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPaymenttimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional float Discount = 15;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional string PromotionID = 16;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getPromotionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPromotionid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string PromotionCode = 17;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getPromotioncode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPromotioncode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional float ShippingCost = 20;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getShippingcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setShippingcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional DeliveryMethod DeliveryMethod = 21;
 * @return {!proto.commUnity.DeliveryMethod}
 */
proto.commUnity.ProductOrder.prototype.getDeliverymethod = function() {
  return /** @type {!proto.commUnity.DeliveryMethod} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.commUnity.DeliveryMethod} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setDeliverymethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional double TotalPrice = 22;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double TotalWeight = 23;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getTotalweight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setTotalweight = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional uint32 NbItems = 24;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getNbitems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setNbitems = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string PaymentRef = 25;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getPaymentref = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPaymentref = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string DeliveryTracking = 26;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getDeliverytracking = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setDeliverytracking = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string PaymentURL = 27;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getPaymenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPaymenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional int32 ScheduledDeliveryDate = 28;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getScheduleddeliverydate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setScheduleddeliverydate = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional DeliveryAddress DeliveryAddress = 30;
 * @return {?proto.commUnity.DeliveryAddress}
 */
proto.commUnity.ProductOrder.prototype.getDeliveryaddress = function() {
  return /** @type{?proto.commUnity.DeliveryAddress} */ (
    jspb.Message.getWrapperField(this, proto.commUnity.DeliveryAddress, 30));
};


/**
 * @param {?proto.commUnity.DeliveryAddress|undefined} value
 * @return {!proto.commUnity.ProductOrder} returns this
*/
proto.commUnity.ProductOrder.prototype.setDeliveryaddress = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.clearDeliveryaddress = function() {
  return this.setDeliveryaddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.ProductOrder.prototype.hasDeliveryaddress = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional string Note = 31;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string PickupPlaceID = 40;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getPickupplaceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPickupplaceid = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string PickupPlaceName = 41;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getPickupplacename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setPickupplacename = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional int64 CancelTimeStamp = 100;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getCanceltimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 100, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setCanceltimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 100, value);
};


/**
 * optional string CancelReason = 101;
 * @return {string}
 */
proto.commUnity.ProductOrder.prototype.getCancelreason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setCancelreason = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional int64 DeliveredTimeStamp = 110;
 * @return {number}
 */
proto.commUnity.ProductOrder.prototype.getDeliveredtimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 110, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrder} returns this
 */
proto.commUnity.ProductOrder.prototype.setDeliveredtimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 110, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ProductOrderLine.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ProductOrderLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ProductOrderLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductOrderLine.toObject = function(includeInstance, msg) {
  var f, obj = {
productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
color: jspb.Message.getFieldWithDefault(msg, 2, 0),
size: jspb.Message.getFieldWithDefault(msg, 3, ""),
quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
totalprice: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
productname: jspb.Message.getFieldWithDefault(msg, 10, ""),
sku: jspb.Message.getFieldWithDefault(msg, 14, ""),
photo: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ProductOrderLine}
 */
proto.commUnity.ProductOrderLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ProductOrderLine;
  return proto.commUnity.ProductOrderLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ProductOrderLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ProductOrderLine}
 */
proto.commUnity.ProductOrderLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {!proto.commUnity.Color} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSize(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalprice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductname(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ProductOrderLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ProductOrderLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ProductOrderLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductOrderLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSize();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTotalprice();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getProductname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string ProductID = 1;
 * @return {string}
 */
proto.commUnity.ProductOrderLine.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Color Color = 2;
 * @return {!proto.commUnity.Color}
 */
proto.commUnity.ProductOrderLine.prototype.getColor = function() {
  return /** @type {!proto.commUnity.Color} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commUnity.Color} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string Size = 3;
 * @return {string}
 */
proto.commUnity.ProductOrderLine.prototype.getSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setSize = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Quantity = 4;
 * @return {number}
 */
proto.commUnity.ProductOrderLine.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double TotalPrice = 5;
 * @return {number}
 */
proto.commUnity.ProductOrderLine.prototype.getTotalprice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setTotalprice = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string ProductName = 10;
 * @return {string}
 */
proto.commUnity.ProductOrderLine.prototype.getProductname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setProductname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string SKU = 14;
 * @return {string}
 */
proto.commUnity.ProductOrderLine.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Photo = 20;
 * @return {string}
 */
proto.commUnity.ProductOrderLine.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductOrderLine} returns this
 */
proto.commUnity.ProductOrderLine.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ShippingCost.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ShippingCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ShippingCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ShippingCost.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
fromprice: jspb.Message.getFieldWithDefault(msg, 10, 0),
toprice: jspb.Message.getFieldWithDefault(msg, 11, 0),
shippingcost: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ShippingCost}
 */
proto.commUnity.ShippingCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ShippingCost;
  return proto.commUnity.ShippingCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ShippingCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ShippingCost}
 */
proto.commUnity.ShippingCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFromprice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setToprice(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setShippingcost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ShippingCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ShippingCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ShippingCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ShippingCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromprice();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getToprice();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getShippingcost();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.ShippingCost.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ShippingCost} returns this
 */
proto.commUnity.ShippingCost.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 FromPrice = 10;
 * @return {number}
 */
proto.commUnity.ShippingCost.prototype.getFromprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ShippingCost} returns this
 */
proto.commUnity.ShippingCost.prototype.setFromprice = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 ToPrice = 11;
 * @return {number}
 */
proto.commUnity.ShippingCost.prototype.getToprice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ShippingCost} returns this
 */
proto.commUnity.ShippingCost.prototype.setToprice = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double ShippingCost = 20;
 * @return {number}
 */
proto.commUnity.ShippingCost.prototype.getShippingcost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.ShippingCost} returns this
 */
proto.commUnity.ShippingCost.prototype.setShippingcost = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.Product.repeatedFields_ = [4,5,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
categoryid: jspb.Message.getFieldWithDefault(msg, 3, ""),
colorsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
sizesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
price: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
description: jspb.Message.getFieldWithDefault(msg, 7, ""),
shortdescription: jspb.Message.getFieldWithDefault(msg, 8, ""),
reference: jspb.Message.getFieldWithDefault(msg, 9, ""),
imagesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
categoryname: jspb.Message.getFieldWithDefault(msg, 11, ""),
url: jspb.Message.getFieldWithDefault(msg, 12, ""),
isavailable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
sku: jspb.Message.getFieldWithDefault(msg, 14, ""),
ean: jspb.Message.getFieldWithDefault(msg, 15, ""),
qt: jspb.Message.getFieldWithDefault(msg, 16, 0),
qtyn: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
weight: jspb.Message.getFieldWithDefault(msg, 20, 0),
categoryorder: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Product}
 */
proto.commUnity.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Product;
  return proto.commUnity.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Product}
 */
proto.commUnity.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryid(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.commUnity.Color>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addColors(values[i]);
      }
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSizes(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReference(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryname(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsavailable(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSku(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEan(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQt(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQtyn(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWeight(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCategoryorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getColorsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getSizesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReference();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getCategoryname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsavailable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSku();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEan();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getQt();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getQtyn();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getCategoryorder();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.Product.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CategoryID = 3;
 * @return {string}
 */
proto.commUnity.Product.prototype.getCategoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setCategoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Color Colors = 4;
 * @return {!Array<!proto.commUnity.Color>}
 */
proto.commUnity.Product.prototype.getColorsList = function() {
  return /** @type {!Array<!proto.commUnity.Color>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.commUnity.Color>} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setColorsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.commUnity.Color} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.addColors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.clearColorsList = function() {
  return this.setColorsList([]);
};


/**
 * repeated string Sizes = 5;
 * @return {!Array<string>}
 */
proto.commUnity.Product.prototype.getSizesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setSizesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.addSizes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.clearSizesList = function() {
  return this.setSizesList([]);
};


/**
 * optional double Price = 6;
 * @return {number}
 */
proto.commUnity.Product.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string Description = 7;
 * @return {string}
 */
proto.commUnity.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ShortDescription = 8;
 * @return {string}
 */
proto.commUnity.Product.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string Reference = 9;
 * @return {string}
 */
proto.commUnity.Product.prototype.getReference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setReference = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string Images = 10;
 * @return {!Array<string>}
 */
proto.commUnity.Product.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string CategoryName = 11;
 * @return {string}
 */
proto.commUnity.Product.prototype.getCategoryname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setCategoryname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string URL = 12;
 * @return {string}
 */
proto.commUnity.Product.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool IsAvailable = 13;
 * @return {boolean}
 */
proto.commUnity.Product.prototype.getIsavailable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setIsavailable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string SKU = 14;
 * @return {string}
 */
proto.commUnity.Product.prototype.getSku = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setSku = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string EAN = 15;
 * @return {string}
 */
proto.commUnity.Product.prototype.getEan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setEan = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 QT = 16;
 * @return {number}
 */
proto.commUnity.Product.prototype.getQt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setQt = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional bool QTYN = 17;
 * @return {boolean}
 */
proto.commUnity.Product.prototype.getQtyn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setQtyn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int32 Weight = 20;
 * @return {number}
 */
proto.commUnity.Product.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint32 CategoryOrder = 30;
 * @return {number}
 */
proto.commUnity.Product.prototype.getCategoryorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Product} returns this
 */
proto.commUnity.Product.prototype.setCategoryorder = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CheckPromoCode.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CheckPromoCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CheckPromoCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckPromoCode.toObject = function(includeInstance, msg) {
  var f, obj = {
deliverymethod: jspb.Message.getFieldWithDefault(msg, 1, 0),
couponcode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CheckPromoCode}
 */
proto.commUnity.CheckPromoCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CheckPromoCode;
  return proto.commUnity.CheckPromoCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CheckPromoCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CheckPromoCode}
 */
proto.commUnity.CheckPromoCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.commUnity.DeliveryMethod} */ (reader.readEnum());
      msg.setDeliverymethod(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CheckPromoCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CheckPromoCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CheckPromoCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CheckPromoCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliverymethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCouponcode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeliveryMethod DeliveryMethod = 1;
 * @return {!proto.commUnity.DeliveryMethod}
 */
proto.commUnity.CheckPromoCode.prototype.getDeliverymethod = function() {
  return /** @type {!proto.commUnity.DeliveryMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.commUnity.DeliveryMethod} value
 * @return {!proto.commUnity.CheckPromoCode} returns this
 */
proto.commUnity.CheckPromoCode.prototype.setDeliverymethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string CouponCode = 2;
 * @return {string}
 */
proto.commUnity.CheckPromoCode.prototype.getCouponcode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CheckPromoCode} returns this
 */
proto.commUnity.CheckPromoCode.prototype.setCouponcode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.Discount.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.Discount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.Discount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Discount.toObject = function(includeInstance, msg) {
  var f, obj = {
discount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.Discount}
 */
proto.commUnity.Discount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.Discount;
  return proto.commUnity.Discount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.Discount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.Discount}
 */
proto.commUnity.Discount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiscount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.Discount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.Discount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.Discount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.Discount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiscount();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional float Discount = 1;
 * @return {number}
 */
proto.commUnity.Discount.prototype.getDiscount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.Discount} returns this
 */
proto.commUnity.Discount.prototype.setDiscount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string Message = 2;
 * @return {string}
 */
proto.commUnity.Discount.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.Discount} returns this
 */
proto.commUnity.Discount.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.commUnity.DiscountOn = {
  DISCOUNTONANY: 0,
  DISCOUNTONPRODUCTS: 1,
  DISCOUNTONCATEGORIES: 2,
  DISCOUNTONSHIPPING: 3
};

/**
 * @enum {number}
 */
proto.commUnity.PromoStatus = {
  PROMODRAFT: 0,
  PROMOPUBLISHED: 1,
  PROMOCANCELLED: 2,
  PROMOEXPIRED: 3
};

/**
 * @enum {number}
 */
proto.commUnity.PromotionType = {
  DISCOUNTPCT: 0,
  DISCOUNTAMOUNT: 1
};

/**
 * @enum {number}
 */
proto.commUnity.DeliveryMethod = {
  DELIVERY: 0,
  PICKUP: 1
};

/**
 * @enum {number}
 */
proto.commUnity.OrderStatus = {
  ANYORDERSTATUS: 0,
  WAITINGFORSHIPMENT: 1,
  SHIPPED: 2,
  DELIVERED: 3,
  WAITINGFORPICKUP: 4,
  ORDERCANCELLED: 5,
  PICKEDUP: 6
};

/**
 * @enum {number}
 */
proto.commUnity.PaymentStatus = {
  ANYPAYMENTSTATUS: 0,
  UNPAID: 1,
  PAID: 2,
  CANCELLED: 3
};

goog.object.extend(exports, proto.commUnity);
