  import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GolfClass, GolfTeacher } from 'src/app/libs/proto/commUnity_pb';
import { ColorConversionService } from 'src/app/service/conversion/color/color-conversion.service';
import { DialogServiceService } from 'src/app/service/dialog/dialog-service.service';
import { GrpcGolfClassLibService } from 'src/app/service/grpc/golf_classes/grpc-golfclass-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { GolfClassComponent } from './golf_class.component';
import * as moment from 'moment';
import { GolfClassesComponent } from './golf_classes.component';

@Component({
  selector: 'app-golf-card',
  templateUrl: './golf_class.card.html',
  styleUrls: ['./golf_class.card.sass']
})
export class GolfCardComponent {
 
  @Input() route: Router;

  @Input() userLib: UserLibService;
  @Input() grpcGolfClassLib: GrpcGolfClassLibService;
  @Input() dlglib: DialogServiceService;
  @Input() storeLib: StorageLibService;
  @Input() colorlib: ColorConversionService;
  
  @Input() readonly: boolean;
  @Input() c: GolfClass;
  @Input() dlgteachers: GolfTeacher[];
  @Input() dlgwaitattend: boolean;
  @Input() T: {};
  @Input() dlgdisplay: string;
  @Input() me: GolfClassComponent;
  @Input() my: GolfClassesComponent;

  dlgteacher: GolfTeacher;
  dlgteacherDisplay : string;

  public static format_hour(t: number): string {
    if (t == 0) return '00:00';
    let h = Math.floor(t / 60).toString();  
    let m = (t % 60).toString();
    return h.padStart(2,'0') + ':' + m.padEnd(2, '0');
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }  

  get format_hour() {
    return GolfCardComponent.format_hour;
  }

  get teachername() {
    return GolfCardComponent.teachername;
  }

  get teacherphoto() {
    return GolfCardComponent.teacherphoto;
  }
  get currencysymbol() {
    return this.userLib.Data.token?.getCustomer().getCurrencysymbol();
  }
  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  public static teachername(id: string, dlgteachers: GolfTeacher[]): string {
    if (!dlgteachers) return '';

    let t = dlgteachers.filter((t) => t.getId() == id)
    if (!t) return '';

    return [t[0].getFirstname(),t[0].getName()].join(' ');
  }  
  public static teacherphoto(id: string, dlgteachers: GolfTeacher[]): string {
    if (!dlgteachers) return '';

    let t = dlgteachers.filter((t) => t.getId() == id)
    if (!t) return '';
    return t[0].getPhoto();
  } 


  attend(c: GolfClass) {
    if (this.userLib.Data.token?.getProfile().getIsguest()) {
      this.userLib.clear();
      this.userLib.Data.signOut = true;
      this.route.navigateByUrl('/login');
      return true;
    }

    //this.dlgdisplay = 'none';
    this.dlgwaitattend = true;
    var msgt = ''
    var msgb = ''
    var msge = '';
    this.grpcGolfClassLib.attendGolfClass(c.getId())
    .then((_) => {
      msgt = this.T['golf_class.attend_title'];
      msgb = this.T['golf_class.ok_attend'];
    })
    .catch((e) => {
      msge = e.toString();
      msgt = this.T['golf_class.err_title'];
      msgb = this.T['golf_class.err_attend'] + msge;
    }).finally( () => {
      this.dlglib.show(msgb, msgt, () => {
        this.dlgwaitattend = false;
        if (msge == '') {

          this.storeLib.set('g-mclass-s', null);
          this.storeLib.cache.myGolfClass = false;

          this.me.updateFilter();             
        }
      });
    });
  }

  static isattend(c: GolfClass, mycls: GolfClass[]) {
    return mycls?.filter((e) => e.getId() == c.getId()).length > 0;
  }

  static canattend(c: GolfClass) {
    if (c.getNbdaybeforestart() == 0) return true;

    let attdy = moment(c.getDate(),'YYYYMMDD').add(-c.getNbdaybeforestart(),'days');
    return  moment() < attdy;
  }

  isPast(c: GolfClass) {
    return (moment() > moment(c.getDate(),'YYYYMMDD')) && this.readonly;
  }

  isattend(c: GolfClass, mycls: GolfClass[]) {
    return GolfCardComponent.isattend(c, mycls);
  }

  canattend(c: GolfClass) {
    return GolfCardComponent.canattend(c);
  }

  canCancel(c: GolfClass) {    
    if (c.getNbdaybeforecannotcancel() == 0) return true;
    
    let canceldy = moment(c.getDate(),'YYYYMMDD').add(-c.getNbdaybeforecannotcancel(),'days');
    return  moment() < canceldy;
  }
  cancel(c: GolfClass) {

    this.dlglib.confirm(this.T['golf_class.cancel_msg'], (r) => {

      if (r.no) { return; }

      var msgt = ''
      var msgb = ''
      var msge = '';
  
      this.grpcGolfClassLib.cancelGolfClassReservation(c.getId())
      .then((_) => {
        msgt = this.T['golf_class.cancel_title'];
        msgb = this.T['golf_class.ok_cancel'];
      })
      .catch((e) => {
        msge = e.toString();
        msgt = this.T['golf_class.err_title'];
        msgb = this.T['golf_class.err_cancel'] + msge;
      }).finally( () => {
        this.dlglib.show(msgb, msgt, () => {
          this.dlgwaitattend = false;
          if (msge == '') {
            //this.dlgdisplay = 'none';
  
            this.storeLib.set('g-mclass-s', null);
            this.storeLib.cache.myGolfClass = false;
            this.my?.loadlist();
          }
        });
      });
  
    }, this.T['golf_class.cancel_title']);
  }

  openteacher(tid: string) {
    this.dlgteacherDisplay = 'block';
    this.dlgteacher = this.dlgteachers.filter((e) => e.getId() == tid)[0];
  }
  has_ics(c: GolfClass) {
    let dy =  parseInt(moment(c.getDate(),'YYYYMMDD').format('YYYYMMDD'));
    return  parseInt(moment().format('YYYYMMDD')) <= dy;
  }
  toolbar_width(c: GolfClass, readonly: boolean) {
    var w = 24;
    if (this.canCancel(c) && readonly) w += 40; 
    if (this.has_ics(c) && readonly) w += 40;
    return w; 
  }
  downloadICS(c: GolfClass) {
    this.grpcGolfClassLib.generateICSConfirmGolfClass(c.getId()).then( (ics) => {
      if (ics && ics.length > 0) {
        let downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([ics[0].getIcs()], { type: 'text/calendar' }));

        downloadLink.download = 'invite-' + moment(c.getDate(),'YYYYMMDD').format('DDMMYYYY') +'.ics';
        downloadLink.click();
      }
    });
  }
}
